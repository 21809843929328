import Lucide from "../../base-components/Lucide";
import { useState, useEffect } from "react";
import WhatsappIcon from "../../assets/images/icon/whatsapp-logo.svg";
import WhatsappChats from "../WhatsappChats";
import { RootState } from "src/stores";
import { connect } from "react-redux";

function Main({ messages }: any) {
  const [openChats, setOpenChats] = useState<boolean>(false);

  const [chatCount, setChatCount] = useState<any>(0);

  useEffect(() => {
    setChatCount(messages?.chatCount);
  }, [messages]);

  return (
    <div>
      <WhatsappChats isOpen={openChats} onClose={() => setOpenChats(false)} />
      <div
        onClick={(event: React.MouseEvent) => {
          event.preventDefault();
          setOpenChats(true);
        }}
        className="fixed bottom-0 right-0 z-50 flex items-center justify-center mb-5 mr-5  rounded-full  cursor-pointer "
      >
        {chatCount > 0 && (
          <div className="py-1 px-2.5 rounded-full bg-[#EF4444] text-white absolute top-0 right-2">
            {chatCount}
          </div>
        )}
        <img src={WhatsappIcon} className="w-20 h-20" />
      </div>
    </div>
  );
}

const mapStateToProps = ({ messages }: RootState) => ({
  messages: messages,
});

const mapDispatchToProps = (dispatch: any) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Main);
