import { connect } from "react-redux";
import Button from "src/base-components/Button";
import _ from "lodash";
import {
  FormHelp,
  FormLabel,
  FormInput,
  FormTextarea,
} from "src/base-components/Form";
import React, { useEffect, useMemo, useState } from "react";
import Lucide from "src/base-components/Lucide";
import SlideoverModal from "src/components/SlideoverModal";
import {
  queryDataProps,
  queryAssignToProps,
  userDataProps,
} from "src/interfaces";
import { RootState, useAppSelector } from "src/stores";
import { queryAssignTo, reAssign } from "src/stores/query";
import { fetchAllUsers } from "src/stores/user";
import LoadingIcon from "src/base-components/LoadingIcon";
import { useNavigate } from "react-router-dom";
import ReactSelect from "src/base-components/ReactSelect";
import { selectAuthUser } from "src/stores/auth";
import { createQueue } from "src/stores/queue";
import { baseUrl } from "src/services/http";
import Litepicker from "src/base-components/Litepicker";
import clsx from "clsx";
import moment from "moment";

export type assignToUsersType = 1 | 2 | 3 | 4 | 5;
export type forType = 1 | 2 | 3 | 6 | 12;

const Component = ({
  headerTitle = "Reassign Billing",
  query,
  users,
  fetchUsers,
  onReAssign,
  visible,
  setVisible,
  model,
  setModel,
  assignToUsersType = 1,
  addSelfUser = false,
}: {
  headerTitle: string;
  query: queryDataProps | undefined;
  users: userDataProps[];
  fetchUsers: (payload: { type: 1 | 2 | 3 }) => void;
  onReAssign: (payload: queryAssignToProps) => void;
  visible: boolean;
  setVisible: Function;
  model: queryAssignToProps;
  setModel: Function;
  assignToUsersType?: assignToUsersType;
  forType?: any;
  addSelfUser?: boolean;
  withDoc?: boolean;
  setBillingToQue: (payload: any) => void;
}) => {
  const authUser = useAppSelector(selectAuthUser);
  const navigate = useNavigate();
  const query_id = query?.id || "";
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setModel({ ...model, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    setSubmitting(true);

    const currentPayment: any = localStorage.getItem("currentPayment");
    const result = JSON.parse(currentPayment);

    const id = result?.quotation_id;

    let payload: queryAssignToProps = {
      id: query_id || model?.id,
      userid: model?.userid,
      remark: model?.remark,
      quotation_id: id,
    };
    if ((model as any)?.status) {
      payload = { ...payload, status: (model as any)?.status } as any;
    }

    onReAssign({
      ...payload,
      callback: (error) => {
        if (!error) {
          setModel({ query_id: query_id } as any);
          setVisible(false);
        }
        setSubmitting(false);
      },
    } as any);
  };

  useEffect(() => {
    if (visible) {
      setLoading(true);
      fetchUsers({
        type: assignToUsersType,
        callback: (userList) => {
          if (
            !!model?.userid &&
            !addSelfUser &&
            !userList?.some((ele) => ele?.id == model?.userid)
          ) {
            setModel({
              ...model,
              userid: "",
            });
          }
          setLoading(false);
        },
      } as any);
    }
  }, [visible]);

  const selfUser = addSelfUser
    ? [
        {
          name: authUser?.name,
          last_name: authUser?.last_name,
          id: authUser?.id,
        },
      ]
    : [];

  const userOptions = [...selfUser, ...users]?.map((item) => {
    return {
      label: `${item?.name || ""} ${item?.last_name || ""}`,
      value: item?.id,
    };
  });

  return (
    <SlideoverModal
      size={"md"}
      visible={visible}
      onClose={() => {
        setVisible(false);
      }}
      headerTitle={headerTitle}
    >
      <form className="h-[100%] w-full flex flex-col" onSubmit={handleSubmit}>
        <div className="text-base font-medium group-[.mode--light]:text-white hidden md:block mb-5">
          Query: <span className="text-primary"># {query?.query_no}</span>
        </div>
        {loading ? (
          <div className="flex w-[100%] justify-center py-12">
            <LoadingIcon className="w-6 h-6 mx-auto" />
          </div>
        ) : (
          <div className="flex-1 w-full mt-3 mb-10 xl:mt-0">
            <FormLabel>Assign to</FormLabel>
            <ReactSelect
              className="flex-1 w-full mt-3 xl:mt-0"
              name="userid"
              placeholder="--Select--"
              required
              value={model?.userid}
              onChange={(_, value) =>
                handleSelectChange({
                  target: { name: "userid", value: value as any },
                } as any)
              }
              options={userOptions}
            />

            <div className="mt-3">
              <div className="flex-1 w-full mt-3 xl:mt-0">
                <FormLabel>Remarks</FormLabel>
                <FormTextarea
                  rows={5}
                  value={model?.remark || ""}
                  name="remark"
                  required
                  onChange={handleSelectChange as any}
                />
                <FormHelp className="text-right">
                  Maximum characters: {model?.remark ? model.remark.length : 0}
                  /2000
                </FormHelp>
              </div>
            </div>
          </div>
        )}
        <Button
          variant="primary"
          type="submit"
          loading={submitting}
          className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto"
        >
          <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-3" />
          Submit
        </Button>
      </form>
    </SlideoverModal>
  );
};

const mapStateToProps = ({ queries: { detail }, users }: RootState) => ({
  query: detail,
  users: users?.data || [],
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchUsers: (payload: any) => dispatch(fetchAllUsers(payload)),
  onReAssign: (payload: any) => dispatch(reAssign(payload)),
  setBillingToQue: (payload: any) => dispatch(createQueue(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
