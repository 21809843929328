import React, {
  createRef,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SlideoverModal from "../SlideoverModal";
import { Dialog, Menu } from "src/base-components/Headless";
import Lucide from "src/base-components/Lucide";
import { FormInput, FormLabel, FormTextarea } from "src/base-components/Form";

import _ from "lodash";
import Tippy from "src/base-components/Tippy";
import clsx from "clsx";
import SimpleBar from "simplebar";

import TestImage from "../../assets/images/TestImages/user.png";
import ResolveChat from "./component/ResolveChat";
import { useNavigate } from "react-router-dom";
import ChatAssignment from "./component/ChatAssignment";
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "src/firebase";
import Button from "src/base-components/Button";
import { store } from "src/stores";
import moment from "moment";
import { setChatCount } from "src/stores/messages";
import FileIcon from "src/base-components/FileIcon";
import { createMessageService, updateChatNameService } from "src/services";
import LoadingIcon from "src/base-components/LoadingIcon";
import PhoneInput from "../PhoneInput";
import whatsappIcon from "../../assets/images/icon/whatsapp-logo.svg";
import { Pencil, XCircle } from "lucide-react";

function WhatsappChats({ isOpen, onClose }) {
  const imageBase = process.env.REACT_APP_IMAGE_BASE_URl;
  const [modalSize, setModalSize] = useState<any>("sm");
  const [filterValue, setFilterValue] = useState<any>(1);
  const [isResolve, setIsResolve] = useState<boolean>(false);
  const [existing, setExisting] = useState<boolean>(false);
  const [chatAssign, setChatAssign] = useState<boolean>(false);

  const [threads, setThreads] = useState<any>([]);
  const [messages, setMessages] = useState<any>([]);
  const [threadId, setThreadId] = useState<any>();

  const [openDailog, setOpenDailog] = useState<boolean>(false);

  const [number, setNumber] = useState<any>();
  const [message, setMessage] = useState<any>("");

  const [selectedThread, setSelectedThread] = useState<any>();

  const [zoomImage, setZoomImage] = useState<boolean>(false);
  const [imgUrl, setImageUrl] = useState<any>("");

  const [sendLoader, setSendLoader] = useState<boolean>(false);
  const [searchedThreads, setSearchedThreads] = useState<any>([]);

  const [isChatInitiated, setIsChatInitiated] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<any>({
    isError: false,
    error: "",
  });

  const [isMessagesLoading, setIsMessagesLoading] = useState<boolean>(false);

  const [renameChat, setRenameChat] = useState<any>({
    isRename: false,
    for: "",
  });

  const [renamedChat, setRenamedChat] = useState<string>("");
  const [uploadedMedia, setUploadedMedia] = useState<any>("");

  const [isDragingMedia, setIsDragingMedia] = useState<boolean>(false);

  const inputFile: any = useRef();

  const auth = store?.getState()?.authUser;

  const updateSeen = async (docId: any) => {
    try {
      await updateDoc(doc(db, "threads", docId), {
        seen: true,
      });
    } catch (error) {
      console.log("Error while updating: ", error);
    }
  };

  const updateUnread = async (docId: any) => {
    try {
      await updateDoc(doc(db, "threads", docId), {
        seen: false,
      });
    } catch (error) {
      console.log("Error while updating: ", error);
    }
  };

  const totalUnseenThreads = (data: any) => {
    try {
      let count = 0;
      data &&
        data?.forEach((item: any) => {
          if (!item?.seen) {
            count += 1;
          }
        });

      store?.dispatch(
        setChatCount({
          chatCount: count > 0 ? count : null,
        })
      );
      return count;
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  useEffect(() => {
    if (threads) {
      totalUnseenThreads(threads);
    }
  }, [threads]);

  const fetchThreads = () => {
    const q = query(collection(db, "threads"), orderBy("updatedAt", "desc"));

    return onSnapshot(
      q,
      (snapshot) => {
        const threadsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setThreads(threadsData);
        setSearchedThreads(threadsData);
      },
      (error) => {
        console.error("Error fetching threads: ", error);
      }
    );
  };

  useEffect(() => {
    try {
      if (threadId && threads) {
        const currentThread: any = threadsData?.find(
          (item: any) => item?.id === threadId
        );
        if (currentThread) {
          setSelectedThread(currentThread);
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  }, [threads, threadId]);

  useEffect(() => {
    const unsubscribe = fetchThreads();
    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, []);

  const fetchLatestMessage = (threadId: any) => {
    const q = query(
      collection(db, "messages"),
      where("threadId", "==", threadId),
      orderBy("createdAt", "asc")
    );

    return onSnapshot(
      q,
      (snapshot) => {
        const latestMsg = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setMessages(latestMsg);

        // if (threadId) {
        //   updateSeen(threadId);
        // }
      },
      (error) => {
        console.error("Error fetching messages: ", error);
      }
    );
  };

  const sendText = async () => {
    try {
      setSendLoader(true);
      if (!selectedThread?.phone) {
        alert("Please add number");
        return;
      }

      if (uploadedMedia?.length < 1 && !message) {
        alert("Please add a message or media");
        return;
      }

      const payload = {
        phone: selectedThread?.phone,
        message: message || null,
        id: auth?.id,
        attachments:
          uploadedMedia && uploadedMedia?.length > 0 ? uploadedMedia : null,
      };

      if (payload) {
        await createMessageService(payload).then((response: any) => {
          setMessage("");
          setUploadedMedia("");
        });
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setSendLoader(false);
    }
  };

  const navigate = useNavigate();
  const scrollableRef = createRef<HTMLDivElement>();

  const onCloseResolve = () => setIsResolve(false);

  useEffect(() => {
    if (scrollableRef.current) {
      new SimpleBar(scrollableRef.current);
    }
  });

  const chatRef = createRef<HTMLDivElement>();

  const handleScoll = () => {
    if (chatRef.current) {
      const lastMessage = chatRef.current.lastElementChild;
      if (lastMessage) {
        lastMessage.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    handleScoll();
  }, [messages]);

  const handleResolveModal = (type: string) => {
    if (type) {
      if (type === "new") {
        setExisting(false);
      } else {
        setExisting(true);
      }
      setIsResolve(true);
    }
  };

  const handleResize = async (thread: any) => {
    try {
      setModalSize("xl");
      setSelectedThread(thread);
      setThreadId(thread?.id);
      handleScoll();
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleFilterTabs = (value: any) => {
    try {
      setFilterValue(value);
    } catch (error) {
      console.log("Error setting filter value: ", error);
    }
  };
  const validatePhoneNumber = (phoneNumber: string): boolean => {
    const regex = /^(?:\+?\d{1,2}\s?)?(\(?\d{3}\)?)[-.\s]?\d{3}[-.\s]?\d{4}$/;

    return regex.test(phoneNumber);
  };

  const handleInitiateChat = async () => {
    try {
      setIsChatInitiated(true);
      if (
        !number ||
        !number?.country_code ||
        !validatePhoneNumber(number?.phone)
      ) {
        setPhoneError({
          isError: true,
          error: "Please enter a valid phone number.",
        });
        return;
      }

      setPhoneError({ isError: false, error: "" });

      const code = number?.country_code?.replace("+", "");

      const paylaod: any = {
        phone: `${code}${number?.phone}`,
        message:
          "Hello! Welcome to Millwoods Travels Inc. We're excited to assist you today!",
        id: auth?.id,
        template: true,
      };

      await createMessageService(paylaod).then((response: any) => {
        setNumber("");
        setOpenDailog(false);
      });
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setIsChatInitiated(false);
      setNumber("");
      setOpenDailog(false);
    }
  };

  const handleMessageKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      sendText();
    } else if (event.ctrlKey || event.metaKey) {
      // Check for Ctrl+V or Cmd+V
      if (event.key === "v" || event.key === "V") {
        event.preventDefault();
        handleClipboardPaste(event);
      }
    }
  };

  const handleClipboardPaste = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    try {
      const clipboard = await navigator.clipboard.read();

      const items: any = clipboard.map((item) => item.types);

      if (items[0].includes("text/plain")) {
        const plainTextItem = clipboard.find((item) =>
          item.types.includes("text/plain")
        );

        if (plainTextItem) {
          const text = await plainTextItem.getType("text/plain");
          const textContent = await text.text();

          setMessage(textContent);
        }
      }

      if (Array.from(uploadedMedia).length > 1) {
        event.preventDefault();
        alert(`Cannot upload more than 2 files`);
        return;
      }

      if (items[0].includes("image/png") || items[0].includes("image/jpeg")) {
        const imageFile =
          (await clipboard[0].getType("image/png")) ||
          (await clipboard[0].getType("image/jpeg"));

        if (imageFile) {
          const file = new File([imageFile], "image.png", {
            type: imageFile.type,
          });

          const MAX_SIZE = 2 * 1024 * 1024;

          if (file.size > MAX_SIZE) {
            alert(
              `File "${file.name}" is too large. Please select a file smaller than 2MB.`
            );
            return false;
          }

          const filesPayload = [
            ...uploadedMedia,
            {
              type: file.type,
              file: file,
              name: file.name,
              preview: URL.createObjectURL(file),
            },
          ];

          setUploadedMedia(filesPayload);
        }
      }
    } catch (error) {
      console.error("Error while handling clipboard paste: ", error);
    }
  };

  const downloadImage = (imageUrl: string) => {
    fetch(imageUrl, {
      method: "GET",
    })
      .then((response) => {
        console.log("Response:", response);
        if (!response.ok) {
          throw new Error("Failed to fetch the image");
        }
        return response.blob();
      })
      .then((blob) => {
        console.log("Blob:", blob);

        const blobUrl = URL.createObjectURL(blob);
        console.log("Blob URL:", blobUrl);

        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = "image.jpg";
        link.click();

        URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => {
        console.error("Error downloading the image:", error);
      });
  };

  const getFileType = (filename: string) => {
    return filename?.split(".").pop();
  };

  const downloadDocument = (docUrl: string) => {
    fetch(docUrl, {
      method: "GET",
    })
      .then((response) => {
        console.log("Response:", response);
        if (!response.ok) {
          throw new Error("Failed to fetch the doc");
        }
        return response.blob();
      })
      .then((blob) => {
        console.log("Blob:", blob);

        const blobUrl = URL.createObjectURL(blob);
        console.log("Blob URL:", blobUrl);

        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = `document.${getFileType(docUrl)}`;
        link.click();

        URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => {
        console.error("Error downloading the image:", error);
      });
  };

  const handleImage = (imgUrl: any) => {
    setImageUrl(imgUrl);
    setZoomImage(true);
  };

  const filterTabs = [
    {
      label: "New",
      value: 1,
    },
    {
      label: "Unread",
      value: 2,
    },
    {
      label: "All",
      value: 3,
    },
  ];

  const threadsData = useMemo(() => {
    try {
      if (searchedThreads && filterValue != null) {
        switch (filterValue) {
          case 1:
            return searchedThreads.filter(
              (item: any) => item?.resolved != true
            );
          case 2:
            return searchedThreads.filter((item: any) => !item?.seen);
          default:
            return searchedThreads;
        }
      }
      return [];
    } catch (error) {
      console.log("Error in useMemo:", error);
      return [];
    }
  }, [filterValue, searchedThreads, auth?.id]);

  useEffect(() => {
    try {
      if (!threadId) return;

      const unsubscribe = fetchLatestMessage(threadId);
      return () => {
        if (typeof unsubscribe === "function") {
          unsubscribe();
        }
      };
    } catch (error) {
      console.log("Error: ", error);
    }
  }, [threadId, threadsData]);

  const handleSearch = (event: any) => {
    try {
      const { value } = event?.target;
      if (value && value?.length > 3) {
        const searched = threads?.filter((thread) => {
          const searchString = value?.toLowerCase();
          return (
            thread?.name?.toLowerCase().includes(searchString) ||
            thread?.phone?.toString().includes(searchString)
          );
        });

        setSearchedThreads(searched);
      } else {
        setSearchedThreads(threads);
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleRenameChat = async (event: any) => {
    try {
      if (event?.keyCode === 13) {
        setRenameChat({ isRename: false, for: "" });
        if (renamedChat && selectedThread && selectedThread?.referenceId) {
          const payload: any = {
            thread_id: selectedThread?.id,
            reference_id: selectedThread?.referenceId,
            name: renamedChat,
          };
          if (payload) {
            await updateChatNameService(payload);
            setRenamedChat("");
          }
        }
      }
    } catch (error) {
      console.log("Error: ", error);
      setRenamedChat("");
    }
  };

  const handleSendMedia = (event: any) => {
    try {
      const target: any = event.target as HTMLInputElement;

      if (Array.from(event.target.files).length > 2) {
        event.preventDefault();
        alert(`Cannot upload more than 2 files`);
        return;
      }

      const MAX_SIZE = 2 * 1024 * 1024;
      const filesPayload = Array.from(target.files)
        .filter((file: any) => {
          if (file.size > MAX_SIZE) {
            alert(
              `File "${file.name}" is too large. Please select a file smaller than 2MB.`
            );
            return false;
          }
          return true;
        })
        .map((file: any) => ({
          type: file.type,
          file: file,
          name: file.name,
          preview: URL.createObjectURL(file),
        }));

      if (filesPayload.length > 0) {
        console.log("Files Payload: ", filesPayload);
        setUploadedMedia(filesPayload);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const removeItem = (index: any) => {
    const updatedMedia = uploadedMedia.filter((_, i) => i !== index);

    setUploadedMedia(updatedMedia);
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragingMedia(true);
  };

  const handleDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragingMedia(false);
  };

  const handleDrop = (event: React.DragEvent) => {
    // Prevent the browser's default behavior (e.g., opening the file in the browser)
    event.preventDefault();
    event.stopPropagation();

    // Check if more than 1 file is already uploaded
    if (Array.from(uploadedMedia).length > 1) {
      alert(`Cannot upload more than 2 files`);
      setIsDragingMedia(false);
      return; // Prevent further action
    }

    // Get the files dropped
    const files = event.dataTransfer.files;

    // Check if more than 2 files are dropped
    if (Array.from(files).length > 2) {
      alert(`Cannot upload more than 2 files`);
      setIsDragingMedia(false);
      return; // Prevent further action
    }

    // Max file size is 2MB
    const MAX_SIZE = 2 * 1024 * 1024;
    const filesPayload = Array.from(files)
      .filter((file: any) => {
        if (file.size > MAX_SIZE) {
          alert(
            `File "${file.name}" is too large. Please select a file smaller than 2MB.`
          );
          return false; // Skip this file
        }
        return true;
      })
      .map((file: any) => ({
        type: file.type,
        file: file,
        name: file.name,
        preview: URL.createObjectURL(file),
      }));

    // Add the valid files to the uploaded media array
    const finalPayload: any = [...uploadedMedia, ...filesPayload];

    // Update the state with the new uploaded files
    if (finalPayload.length > 0) {
      setUploadedMedia(finalPayload);
    }

    // Reset the drag status
    setIsDragingMedia(false);
  };

  return (
    <SlideoverModal
      size={modalSize}
      headerLeft={
        <div className="flex flex-row gap-x-1 items-center ">
          <img src={whatsappIcon} alt="WhatsApp" className="w-10 h-10" />
          <h1 className="text-base font-medium">WhatsApp</h1>
        </div>
      }
      headerRight={
        <div className="flex flex-row items-center gap-x-5">
          <Button
            type="button"
            className=""
            variant="outline-primary"
            onClick={() => setOpenDailog(true)}
          >
            New Chat
          </Button>
        </div>
      }
      visible={isOpen}
      className="p-1 overflow-hidden"
      onClose={() => {
        setIsDragingMedia(false);
        onClose();
        setModalSize("sm");
        setSelectedThread(null);
        setThreadId(null);
      }}
    >
      {/* Zoom image */}
      <Dialog
        open={zoomImage}
        onClose={() => {
          setZoomImage(false);
          setImageUrl("");
        }}
      >
        <Dialog.Panel className="mt-10 relative p-5  w-full">
          <img src={imgUrl} alt="" className="h-full w-full object-cover" />
        </Dialog.Panel>
      </Dialog>

      {/* Handling Media upload */}
      <input
        type="file"
        id="file"
        ref={inputFile}
        multiple
        accept="application/pdf, image/*"
        title="Select media"
        onChange={handleSendMedia}
        style={{ display: "none" }}
      />

      {/* Adding Phone */}
      <Dialog
        open={openDailog}
        onClose={() => {
          setOpenDailog(false);
          setNumber("");
          setPhoneError({ isError: false, error: "" });
        }}
      >
        <Dialog.Panel className="mt-40 relative p-5">
          <div className="mb-3">
            <div className="flex space-x-2">
              <FormLabel>Phone</FormLabel>
            </div>
            {phoneError?.isError && (
              <p className="text-red-700 ">{phoneError?.error}</p>
            )}
            <div className="w-full">
              <PhoneInput
                {...({} as any)}
                value={`${number?.country_code}${number?.phone}`}
                placeholder="#Phone"
                required={true}
                onChange={(data) => {
                  setPhoneError({ isError: false, error: "" });
                  setNumber({
                    country_code: data?.dialCode,
                    phone: data?.phone,
                  });
                }}
              />
            </div>
          </div>
          <div className="w-full flex justify-end">
            <Button
              type="button"
              className=""
              disabled={isChatInitiated}
              loading={isChatInitiated}
              loadingColor={"#24347E"}
              variant="outline-primary"
              onClick={handleInitiateChat}
            >
              Initiate chat
            </Button>
          </div>
          {/* <img
            src={EnterKey}
            alt=""
            className="absolute inset-y-0 right-3 z-10 w-4 h-4 my-auto ml-4 stroke-[1.3] text-slate-500/90"
          /> */}
        </Dialog.Panel>
      </Dialog>

      <div className="flex flex-row gap-5 overflow-hidden">
        <div className="flex flex-col p-2 w-[30rem] ">
          <div className="">
            <div className="flex flex-col space-y-4">
              {/* <div className=" w-full">
                <Button
                  type="button"
                  className="w-full"
                  variant="outline-primary"
                  onClick={() => setOpenDailog(true)}
                >
                  Add Secret Key
                </Button>
              </div> */}
              <div className="flex justify-between items-center">
                <div className="relative w-full flex flex-row ">
                  <Lucide
                    icon="Search"
                    className="absolute inset-y-0 left-0 z-10 w-4 h-4 my-auto ml-4 stroke-[1.3] text-slate-500/90"
                  />
                  <FormInput
                    type="text"
                    rounded
                    placeholder="Search"
                    className={`pl-10 py-2.5 bg-[#F0F0F0] rounded-md border-none`}
                    onChange={(event: any) => handleSearch(event)}
                  />
                </div>
              </div>

              <div className="flex justify-between w-full p-2 bg-[#F0F0F0] rounded-md">
                {filterTabs &&
                  filterTabs.map((item: any, index: number) => (
                    <div
                      key={index}
                      className={`py-1 w-1/2 px-6 rounded-md cursor-pointer flex justify-center items-center ${
                        item.value === filterValue ? "bg-white" : ""
                      }`}
                      onClick={() => handleFilterTabs(item.value)}
                    >
                      {item.label}
                    </div>
                  ))}
              </div>
            </div>
            <div className="flex flex-col gap-3 mt-4 px-2 rounded-md overflow-y-auto max-h-[44rem]">
              {/* Here chat object start */}

              {threadsData &&
                threadsData?.map((item: any, index: number) => {
                  const timestamp: any = item?.updatedAt;
                  const milliseconds =
                    timestamp?.seconds * 1000 +
                    timestamp?.nanoseconds / 1000000;
                  const formattedDateTime = moment(milliseconds).format(
                    "DD MMM, YYYY hh:mm A"
                  );

                  const isItem = item?.seen === false;
                  // const authId = auth?.id?.toString();
                  // const isMine =
                  //   authId == item?.assignedTo ||
                  //   authId == item?.participant_id ||
                  //   authId == item?.assignedBy;

                  return (
                    <div key={index}>
                      <div
                        key={index}
                        className={`flex items-center gap-4 py-2.5 -mx-2 rounded-lg cursor-pointer hover:bg-slate-50 p-1 ${
                          selectedThread?.id == item?.id ? "bg-slate-100" : ""
                        } `}
                        onClick={() => handleResize(item)}
                      >
                        <div className="relative">
                          <div className="w-12 h-12 overflow-hidden border-2 rounded-full image-fit border-slate-200/70">
                            <img alt="Image" src={TestImage} />
                          </div>
                        </div>
                        <div className="w-full">
                          <div className="flex items-center w-full">
                            <div className="font-medium max-w-[7rem] md:max-w-[8rem] truncate">
                              {item?.name ?? item?.phone}
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="text-slate-500/90 max-w-[7rem] md:max-w-[10rem] truncate">
                              {item?.latest_message?.length > 30
                                ? `${item?.latest_message?.slice(0, 30)}...`
                                : item?.latest_message}
                            </div>
                            {isItem && (
                              <div className="flex items-center gap-2 ml-auto">
                                <div className="w-2 h-2 rounded-full bg-[#24357F] flex items-center justify-center text-xs">
                                  {/* Display additional content here */}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="flex items-center gap-2 ml-auto">
                            {item?.resolved && (
                              <div className="w-2 h-2 rounded-full bg-[#D3D7E6]" />
                            )}
                            <div className="text-xs text-slate-500/90">
                              {formattedDateTime}
                            </div>
                          </div>
                        </div>

                        {modalSize === "xl" && !isItem && (
                          <Menu className="relative">
                            <Menu.Button className=" text-slate-500 hover:text-primary">
                              <Lucide
                                icon="MoreVertical"
                                className="w-4 h-4 text-primary fill-primary/10 mr-2"
                              />
                            </Menu.Button>
                            <Menu.Items className="absolute -bottom-4 w-40 -left-[11.5rem]">
                              <Menu.Item onClick={() => updateUnread(item?.id)}>
                                Unread
                              </Menu.Item>
                            </Menu.Items>
                          </Menu>
                        )}
                      </div>
                    </div>
                  );
                })}

              {/* Here chat object end */}
            </div>
          </div>
        </div>

        {/* Chat section */}
        {modalSize === "xl" && (
          <div className={`flex flex-col w-full  gap-y-7 relative `}>
            <div
              className="flex flex-col p-5  "
              style={{ borderLeft: "1px solid #e2e8f0b3" }}
            >
              <div className="flex items-center gap-3.5 border-b border-dashed pb-5">
                <div>
                  <div className="w-12 h-12 overflow-hidden rounded-full image-fit border-[3px] border-slate-200/70">
                    <img alt="image" src={TestImage} />
                  </div>
                </div>
                <div>
                  <div className="font-medium truncate max-w-[9rem] md:max-w-none flex flex-row items-center gap-x-2">
                    {selectedThread &&
                    renameChat?.isRename &&
                    renameChat?.for === selectedThread?.phone ? (
                      <div>
                        <FormInput
                          type="text"
                          value={renamedChat}
                          placeholder="Name"
                          className="rounded-[0.5rem] h-8"
                          onChange={(event: any) =>
                            setRenamedChat(event?.target?.value)
                          }
                          onKeyDown={(event: any) => handleRenameChat(event)}
                        />
                      </div>
                    ) : (
                      selectedThread?.name ?? selectedThread?.phone
                    )}
                    {!renameChat?.isRename ? (
                      <Pencil
                        size={14}
                        className="cursor-pointer"
                        onClick={() => {
                          setRenameChat({
                            isRename: true,
                            for: selectedThread?.phone,
                          });
                        }}
                      />
                    ) : (
                      <XCircle
                        size={14}
                        className="cursor-pointer"
                        color="red"
                        onClick={() =>
                          setRenameChat({
                            isRename: false,
                            for: "",
                          })
                        }
                      />
                    )}
                  </div>
                  <div className="text-slate-500 mt-0.5 truncate max-w-[9rem] md:max-w-none">
                    {`+${selectedThread?.phone}`}
                  </div>
                </div>
                <div className="flex gap-2 ml-auto">
                  {!selectedThread?.resolved ? (
                    <Menu className="">
                      <Menu.Button className=" text-slate-500 hover:text-primary">
                        <Tippy
                          as="a"
                          className="flex items-center justify-center border rounded-full px-3 py-2 border-primary/30 bg-primary/5"
                          content="Resolve Query"
                        >
                          <Lucide
                            icon="Check"
                            className="w-4 h-4 text-primary fill-primary/10 mr-2"
                          />
                          <p>Resolve</p>
                        </Tippy>
                      </Menu.Button>
                      <Menu.Items className="w-40">
                        <Menu.Item
                          onClick={() => handleResolveModal("existing")}
                        >
                          Existing Customer
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => {
                            navigate("/create-query");

                            onClose();
                          }}
                        >
                          New Customer
                        </Menu.Item>
                      </Menu.Items>
                    </Menu>
                  ) : (
                    <div className="flex items-center justify-center border rounded-full px-3 py-2 border-primary/30 bg-primary/5">
                      <p className="text-primary">Resolved</p>
                    </div>
                  )}

                  {/* <Menu className="w-5 h-6 my-auto group-[.right]:-ml-8 group-[.right]:left-0 ">
                    <Menu.Button className="w-5 h-5 text-slate-500">
                      <Lucide
                        icon="MoreVertical"
                        className="w-5 h-5 stroke-slate-400/70 fill-slate-400/70"
                      />
                    </Menu.Button>
                    <Menu.Items className="w-40">
                      <Menu.Item onClick={() => setChatAssign(true)}>
                        Assign Chat
                      </Menu.Item>
                    </Menu.Items>
                  </Menu> */}
                </div>
              </div>

              <div
                onClick={() => updateSeen(selectedThread?.id)}
                className={clsx([
                  "h-[40.6rem] -mx-3 overflow-y-auto [&:-webkit-scrollbar]:w-0 [&:-webkit-scrollbar]:bg-transparent",
                  "[&_.simplebar-content]:p-0 [&_.simplebar-track.simplebar-vertical]:w-[10px] [&_.simplebar-track.simplebar-vertical]:mr-0.5 [&_.simplebar-track.simplebar-vertical_.simplebar-scrollbar]:before:bg-slate-400/20",
                ])}
                style={{
                  height: uploadedMedia?.length > 0 ? "36rem" : "40.6rem",
                }}
                ref={scrollableRef}
              >
                {isMessagesLoading ? (
                  <>Loading</>
                ) : (
                  <div
                    className="flex flex-col gap-3.5 py-5 px-3 "
                    ref={chatRef}
                  >
                    {messages &&
                      messages?.map((item: any, index: number) => {
                        const timestamp: any = item?.createdAt;
                        const milliseconds =
                          timestamp?.seconds * 1000 +
                          timestamp?.nanoseconds / 1000000;
                        const formattedTime = moment(milliseconds)
                          .format("hh:mma")
                          .toUpperCase();
                        const formattedDate =
                          moment(milliseconds).format("MM-DD-YY");

                        const isToday = moment().isSame(
                          moment(milliseconds),
                          "day"
                        );

                        const prevMessage = messages[index - 1];
                        const prevTimestamp: any = prevMessage?.createdAt;
                        const prevMilliseconds =
                          prevTimestamp?.seconds * 1000 +
                          prevTimestamp?.nanoseconds / 1000000;
                        const prevFormattedDate = prevMessage
                          ? moment(prevMilliseconds).format("MM-DD-YY")
                          : "";

                        const showDateSeparator =
                          prevFormattedDate !== formattedDate;

                        const isMine = item?.username == item?.phone;

                        return (
                          <div
                            key={item?.id}
                            className="flex relative flex-col gap-3.5 overflow-hidden"
                          >
                            {showDateSeparator && (
                              <div className="text-center text-xs text-gray-500 mt-2 mb-2">
                                {isToday ? "Today" : formattedDate}
                              </div>
                            )}

                            <div
                              className={clsx([
                                "max-w-[90%] sm:max-w-md mr-auto group [&.right]:mr-0 [&.right]:ml-auto flex items-end gap-3 [&.right]:flex-row-reverse relative",
                                {
                                  right: isMine ? 0 : 1,
                                },
                              ])}
                            >
                              <div
                                className={`border px-4 pt-3 pb-4 rounded-r-xl rounded-tl-xl ${
                                  isMine ? "bg-slate-50/80" : "bg-[#233580]/20"
                                } group-[.right]:text-right group-[.right]:rounded-l-xl group-[.right]:rounded-br-none border-slate-200/80 max-w-[100%] break-words`}
                              >
                                {item?.type == "image" ? (
                                  <div className="mt-3 mb-4 w-64 h-32 overflow-hidden rounded-lg image-fit group-[.right]:ml-auto">
                                    <img
                                      alt={item?.text}
                                      className="p-0.5 border rounded-lg saturate-150 cursor-zoom-in"
                                      onClick={() =>
                                        handleImage(
                                          item?.media_url
                                            ? `${imageBase}${item?.media_url}`
                                            : ""
                                        )
                                      }
                                      src={
                                        item?.media_url
                                          ? `${imageBase}${item?.media_url}`
                                          : ""
                                      }
                                    />
                                  </div>
                                ) : item?.type == "document" ? (
                                  <div>
                                    <FileIcon
                                      className="w-11 h-11"
                                      variant="file"
                                      type={getFileType(
                                        item?.media_url?.split("/").pop()
                                      )}
                                    />
                                  </div>
                                ) : null}
                                <div>
                                  {typeof item?.text === "object" ? (
                                    <div>
                                      <div>{item?.text?.query}</div>
                                      <div>{item?.text?.name}</div>
                                      <div>{item?.text?.email}</div>
                                    </div>
                                  ) : (
                                    <div>{item?.text}</div>
                                  )}
                                </div>
                                <div
                                  className="font-light"
                                  style={{ fontSize: 12 }}
                                >
                                  {formattedTime}
                                </div>

                                {!isMine && (
                                  <div className="mt-1">
                                    {item?.sent && !item?.delivered ? (
                                      <Lucide
                                        icon="Check"
                                        className="w-5 h-5 text-primary ml-auto"
                                      />
                                    ) : item?.read ? (
                                      <Lucide
                                        icon="CheckCheck"
                                        className="w-5 h-5 text-green-600 ml-auto"
                                      />
                                    ) : item?.delivered ? (
                                      <Lucide
                                        icon="CheckCheck"
                                        className="w-5 h-5 text-primary ml-auto"
                                      />
                                    ) : null}
                                  </div>
                                )}
                              </div>

                              {(item?.type === "image" ||
                                item?.type === "document") && (
                                <Menu className="absolute inset-y-0 right-0 w-5 h-5 my-auto -mr-8 group-[.right]:-ml-8 group-[.right]:left-0">
                                  <Menu.Button className="w-5 h-5 text-slate-500">
                                    <Lucide
                                      icon="MoreVertical"
                                      className="w-5 h-5 stroke-slate-400/70 fill-slate-400/70"
                                    />
                                  </Menu.Button>
                                  <Menu.Items
                                    className={[
                                      isMine
                                        ? "-right-[10.5rem]"
                                        : "-left-[11.5rem]",
                                      "w-40 absolute -bottom-4",
                                    ]}
                                  >
                                    <Menu.Item
                                      className=""
                                      onClick={() => {
                                        const mediaUrl = item?.media_url
                                          ? `${imageBase}${item?.media_url}`
                                          : "";
                                        if (item?.type === "image") {
                                          downloadImage(mediaUrl);
                                        } else if (item?.type === "document") {
                                          downloadDocument(mediaUrl);
                                        }
                                      }}
                                    >
                                      <Lucide
                                        icon="Download"
                                        className="w-4 h-4 mr-2"
                                      />
                                      Download
                                    </Menu.Item>
                                  </Menu.Items>
                                </Menu>
                              )}
                            </div>
                            {selectedThread?.customer_replied === false && (
                              <div className="flex flex-row items-center justify-center">
                                <div className="text-center text-xs text-grey-600 mt-2 mb-2 bg-[#FCF8E3] w-2/3 rounded-md py-3 px-2">
                                  Your further messages will not be delivered
                                  until the customer responds. Please wait for
                                  their reply.
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>

              {uploadedMedia && uploadedMedia?.length > 0 && (
                <div className="h-20 rounded-md p-2 flex flex-row gap-x-3 flex-wrap  w-full items-center">
                  {uploadedMedia?.map((item: any, index: any) => {
                    const { type, preview } = item;

                    const isImage = (fileType: any) => {
                      return fileType.startsWith("image/");
                    };

                    return (
                      <div className="relative">
                        <Lucide
                          icon="X"
                          onClick={() => removeItem(index)}
                          className="w-5 h-5 text-white bg-danger absolute -right-2 border border-danger rounded-full cursor-pointer"
                        />

                        {isImage(type) ? (
                          <div key={index}>
                            <img src={preview} className="w-16 h-16" />
                          </div>
                        ) : (
                          <div key={index}>
                            <Lucide
                              icon="File"
                              className="w-16 h-16 text-primary fill-primary/10"
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {/* <Lucide
                    icon="PlusCircle"
                    // onClick={() => removeItem(index)}
                    className="w-8 h-8 text-primary fill-primary/10 rounded-full cursor-pointer ml-5"
                  /> */}
                </div>
              )}
              {!selectedThread?.resolved && (
                <>
                  {isDragingMedia ? (
                    <div
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      className="w-full h-[10%] bg-gray-400/20 p-2 "
                    >
                      <div className="h-full w-full border-2 border-dashed border-white flex justify-center items-center">
                        <div className="flex justify-center items-center">
                          <Lucide
                            icon="FileInput"
                            className="text-grey-500 w-[3rem] h-[3rem]"
                          />
                          <p className="text-grey-500 text-2xl">
                            Drop Files to Send
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="relative " onDragOver={handleDragOver}>
                      <FormTextarea
                        onClick={() => updateSeen(selectedThread?.id)}
                        value={message}
                        onChange={(e: any) => setMessage(e?.target?.value)}
                        onKeyDown={(event: any) => handleMessageKeyDown(event)}
                        className="-mb-1.5 mt-1.5 pr-16 rounded-xl resize-none"
                        placeholder="Type a message..."
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center justify-center w-[6rem] gap-x-3">
                        <div
                          className={`flex items-center justify-center border-transparent rounded-full  w-9 h-9 box bg-gradient-to-b from-theme-1/90 to-theme-2/90 mt-2 ${
                            sendLoader ? "cursor-not-allowed" : "cursor-pointer"
                          }`}
                          onClick={() => inputFile && inputFile.current.click()}
                        >
                          <Lucide
                            icon="Paperclip"
                            className="stroke-[1.3] w-4 h-4 -ml-0.5 text-white/70"
                          />
                        </div>
                        <div
                          className={`flex items-center justify-center border-transparent rounded-full  w-9 h-9 box bg-gradient-to-b from-theme-1/90 to-theme-2/90 mt-2 ${
                            sendLoader ? "cursor-not-allowed" : "cursor-pointer"
                          }`}
                          onClick={!sendLoader ? sendText : () => {}}
                        >
                          {sendLoader ? (
                            <LoadingIcon
                              icon="oval"
                              color={"white"}
                              className="w-5 h-5 text-center mx-auto"
                            />
                          ) : (
                            <Lucide
                              icon="Send"
                              className="stroke-[1.3] w-4 h-4 -ml-0.5 text-white/70"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>

      <ResolveChat
        isOpen={isResolve}
        onClose={onCloseResolve}
        closeParent={() => {
          onClose();
          setModalSize("sm");
        }}
        thread={selectedThread}
      />
      <ChatAssignment
        isOpen={chatAssign}
        onClose={() => setChatAssign(false)}
        thread={selectedThread}
        closeParent={() => {
          onClose();
          setModalSize("sm");
        }}
      />
    </SlideoverModal>
  );
}

export default memo(WhatsappChats);
