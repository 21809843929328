import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUT_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
// const firebaseConfig = {
//   apiKey: "AIzaSyAk_fQTbq8KmPUdevkwt71Riq42J8JMjxs",
//   authDomain: "cyber-ace.firebaseapp.com",
//   projectId: "cyber-ace",
//   storageBucket: "cyber-ace.appspot.com",
//   messagingSenderId: "634924887858",
//   appId: "1:634924887858:web:9270c90ce5ff503166312b",
//   measurementId: "G-G2DP3C4TGK",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
