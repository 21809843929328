import { useEffect, useState } from "react";
import SlideoverModal from "../SlideoverModal";
import clsx from "clsx";

import { RootState, store } from "src/stores";
import { connect } from "react-redux";
import moment from "moment";
import { authUserProps } from "src/interfaces";
import { Dialog } from "@headlessui/react";
import FileIcon from "src/base-components/FileIcon";

function ChatHistory({ isOpen, onClose }) {
  const messages = store?.getState()?.messages;
  const auth: authUserProps = store?.getState()?.authUser;

  const [threads, setThreads] = useState<any>([]);
  const [zoomImage, setZoomImage] = useState<boolean>(false);
  const [imgUrl, setImageUrl] = useState<any>("");

  const createSectionList = (data) => {
    return data.threads.map((thread) => {
      const threadCreatedAt = new Date(thread.created_at).toDateString();
      const threadUpdatedAt = new Date(thread.updated_at).toDateString();

      const messages = thread.messages.map((message) => {
        const isMine = message?.sender_id == auth?.id?.toString();
        const messageText = message.message_content;

        const attachments =
          message.attachments && message.attachments.length > 0
            ? message.attachments.map((att) => ({
                mediaUrl: `${process.env.REACT_APP_IMAGE_BASE_URl}${att.attachment}`,
                caption: att.attachment_caption || "",
                type: message?.message_type,
              }))
            : [];

        return {
          messageText,
          isMine,
          attachments,
        };
      });

      return {
        threadCreatedAt,
        messages,
        threadUpdatedAt,
      };
    });
  };

  useEffect(() => {
    if (messages?.history) {
      setThreads(createSectionList(messages?.history));
    }
  }, [messages]);

  const handleImage = (imgUrl: any) => {
    setImageUrl(imgUrl);
    setZoomImage(true);
  };

  const getFileType = (filename: string) => {
    return filename?.split(".").pop();
  };

  return (
    <SlideoverModal
      size={"lg"}
      headerTitle={"Chat History"}
      visible={isOpen}
      className="p-1"
      onClose={() => {
        onClose();
      }}
    >
      {/* Document Preview */}

      <SlideoverModal
        size="xl"
        visible={zoomImage}
        onClose={() => {
          setZoomImage(false);
        }}
        headerTitle={`Preview`}
      >
        <div className="h-[100%]">
          {imgUrl && <iframe src={imgUrl} width="100%" height="100%" />}
        </div>
      </SlideoverModal>

      <div className="flex flex-row gap-5 overflow-hidden">
        {/* Chat section */}

        <div className="flex flex-col w-full">
          {threads.map((thread, threadIndex) => (
            <div key={threadIndex} className="flex flex-col p-5">
              <div className="text-center text-xs flex justify-center text-gray-600 ">
                <div className="bg-gray-200 p-1 rounded-lg w-fit">{`Chat Started: ${thread.threadCreatedAt}`}</div>
              </div>

              <div>
                <div className="flex flex-col gap-3.5 py-5 px-3">
                  {thread.messages.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="flex relative flex-col gap-3.5 overflow-hidden"
                      >
                        {/* Message box */}
                        <div
                          className={clsx([
                            "max-w-[90%] sm:max-w-none relative mr-auto group [&.right]:mr-0 [&.right]:ml-auto flex items-end gap-3 [&.right]:flex-row-reverse ",
                            { right: item?.isMine },
                          ])}
                        >
                          <div
                            className={`border px-4 pt-3 pb-4 rounded-r-xl rounded-tl-xl ${
                              item?.isMine
                                ? "bg-[#233580]/20"
                                : " bg-slate-50/80"
                            } group-[.right]:text-right group-[.right]:rounded-l-xl group-[.right]:rounded-br-none border-slate-200/80`}
                          >
                            {/* Handle attachments */}
                            {item.attachments &&
                              item.attachments.length > 0 && (
                                <div className="mt-3">
                                  {item.attachments.map(
                                    (attachment, attIndex) => (
                                      <div key={attIndex}>
                                        <div className="w-full mt-2">
                                          {attachment?.type == "document" ? (
                                            <div
                                              onClick={() => {
                                                handleImage(
                                                  attachment.mediaUrl
                                                );
                                              }}
                                            >
                                              <FileIcon
                                                className="w-11 h-11"
                                                variant="file"
                                                type={getFileType(
                                                  attachment.mediaUrl
                                                    ?.split("/")
                                                    .pop()
                                                )}
                                              />
                                            </div>
                                          ) : (
                                            <>
                                              {attachment.mediaUrl && (
                                                <div className="mt-3 mb-4 w-64 h-32 overflow-hidden rounded-lg image-fit group-[.right]:ml-auto">
                                                  <img
                                                    alt={attachment.caption}
                                                    className="p-0.5 border rounded-lg saturate-150 "
                                                    src={attachment.mediaUrl}
                                                    onClick={() => {
                                                      handleImage(
                                                        attachment.mediaUrl
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </div>
                                        <div>{attachment.caption}</div>
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                            <div>{item.messageText || ""}</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="text-center text-xs flex justify-center text-gray-600 ">
                <div className="bg-gray-200 p-1 rounded-lg w-fit">{`Chat Resolved: ${thread.threadUpdatedAt}`}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* </div> */}
    </SlideoverModal>
  );
}

const mapStateToProps = ({}: RootState) => ({});
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ChatHistory);
