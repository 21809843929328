import { FormCheck, FormInput, FormLabel } from "../../../base-components/Form";
import Button from "../../../base-components/Button";
import clsx from "clsx";
import _ from "lodash";
import logo from "../../../assets/images/brand/logo.webp";
import { connect } from "react-redux";
import { RootState } from "src/stores";
import { login } from "src/stores/auth";
import { loginProps } from "src/interfaces";
import { useEffect, useState } from "react";
import Tippy from "src/base-components/Tippy";

import SlideoverModal from "src/components/SlideoverModal";
import Lucide from "src/base-components/Lucide";
import BookOpenText from "../../../assets/images/icon/bookOpenText.svg";

const Component = ({
  handleLogin,
}: {
  handleLogin: (payload: loginProps) => void;
}) => {
  const [loading, setLoading] = useState(false);

  const [crmVersion, setCrmVersion] = useState<any>("");
  const [raiseTicket, setRaiseTicket] = useState<boolean>(false);

  useEffect(() => {
    const setingVerion = setTimeout(() => {
      setCrmVersion(process.env.REACT_APP_CRM_VERSION);
    }, 500);
    return () => clearTimeout(setingVerion);
  }, []);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const username =
      new FormData(event.currentTarget).get("username")?.toString() || "";
    const password =
      new FormData(event.currentTarget).get("password")?.toString() || "";
    const isPersist =
      Boolean(new FormData(event.currentTarget).get("isPersist")) || false;
    setLoading(true);
    handleLogin({
      username,
      password,
      isPersist,
      callback: () => setLoading(false),
    } as any);
  };
  return (
    <>
      <div className="container grid grid-cols-12 lg:max-w-[1550px] 2xl:max-w-[1750px] py-10 px-5 sm:py-14 sm:px-10 md:px-36 lg:py-0 lg:pl-14 lg:pr-12 xl:px-24">
        <div
          className={clsx([
            "relative z-50 h-full col-span-12 p-7 sm:p-14 bg-white rounded-2xl lg:bg-transparent lg:pr-10 lg:col-span-5 xl:pr-24 2xl:col-span-4 lg:p-0",
            "before:content-[''] before:absolute before:inset-0 before:-mb-3.5 before:bg-white/40 before:rounded-2xl before:mx-5",
          ])}
        >
          <div className="relative z-10 flex flex-col justify-center w-full h-full py-2 lg:py-32">
            <img src={logo} className=" w-40" />
            <div className="mt-10">
              <div className="text-2xl font-medium">Sign In</div>
              <form onSubmit={onSubmit} className="mt-6">
                <FormLabel>Username*</FormLabel>
                <FormInput
                  required
                  name="username"
                  type="text"
                  className="block px-4 py-3.5 rounded-[0.6rem] border-slate-300/80"
                  placeholder={"Enter username"}
                />
                <FormLabel className="mt-4">Password*</FormLabel>
                <FormInput
                  required
                  name="password"
                  type="password"
                  className="block px-4 py-3.5 rounded-[0.6rem] border-slate-300/80"
                  placeholder="Enter password"
                />
                <div className="flex mt-4 text-xs text-slate-500 sm:text-sm">
                  <div className="flex items-center mr-auto">
                    <FormCheck.Input
                      id="remember-me"
                      name="isPersist"
                      type="checkbox"
                      className="mr-2.5 border"
                    />
                    <label
                      className="cursor-pointer select-none"
                      htmlFor="remember-me"
                    >
                      Remember me
                    </label>
                  </div>
                  <a href="">Forgot Password?</a>
                </div>
                <div className="mt-5 text-center xl:mt-8 xl:text-left">
                  <Button
                    type="submit"
                    variant="primary"
                    rounded
                    className="bg-gradient-to-r from-primary/70 to-secondary/70 w-full py-3.5 xl:mr-3"
                    loading={loading}
                  >
                    {"Sign In"}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed container grid w-screen inset-0 h-screen grid-cols-12 lg:max-w-[1550px] 2xl:max-w-[1750px] pl-14 pr-12 xl:px-24">
        <div
          className={clsx([
            "relative h-screen col-span-12 lg:col-span-5 2xl:col-span-4 z-20",
            "after:bg-white after:hidden after:lg:block after:content-[''] after:absolute after:right-0 after:inset-y-0 after:bg-gradient-to-b after:from-white after:to-slate-100/80 after:w-[800%] after:rounded-[0_1.2rem_1.2rem_0/0_1.7rem_1.7rem_0]",
            "before:content-[''] before:hidden before:lg:block before:absolute before:right-0 before:inset-y-0 before:my-6 before:bg-gradient-to-b before:from-white/10 before:to-slate-50/10 before:bg-white/50 before:w-[800%] before:-mr-4 before:rounded-[0_1.2rem_1.2rem_0/0_1.7rem_1.7rem_0]",
          ])}
        ></div>
        <div
          className={clsx([
            "h-full col-span-7 2xl:col-span-8 lg:relative",
            "before:content-[''] before:absolute before:lg:-ml-10 before:left-0 before:inset-y-0 before:bg-gradient-to-b before:from-primary before:to-secondary before:w-screen before:lg:w-[800%]",
            "after:content-[''] after:absolute after:inset-y-0 after:left-0 after:w-screen after:lg:w-[800%] after:bg-texture-white after:bg-fixed after:bg-center after:lg:bg-[25rem_-25rem] after:bg-no-repeat",
          ])}
        >
          <div className="sticky top-0 z-10 flex-col justify-center hidden h-screen ml-16 lg:flex xl:ml-28 2xl:ml-36">
            <div className="leading-[1.4] text-[2.6rem] xl:text-5xl font-medium xl:leading-[1.2] text-white">
              Welcome to Millwoods Travels
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-between p-4 z-10 absolute bottom-0 right-0">
        {crmVersion && (
          <div className="flex justify-start mr-10">
            <p className="text-gray-300 text-[12px]">{`v ${crmVersion}`}</p>
          </div>
        )}

        <div className="flex gap-x-5">
          <Tippy
            as="span"
            options={{ theme: "light" }}
            className="flex items-center"
            content={"Report an Issue"}
          >
            <Lucide
              icon="HelpCircle"
              color="white"
              className="w-[20px] h-[20px]"
              onClick={() => setRaiseTicket(true)}
            />
          </Tippy>

          <Tippy
            as="span"
            options={{ theme: "light" }}
            className="flex items-center"
            content={"CRM User Guide"}
          >
            <img
              src={BookOpenText}
              className="w-5 h-5"
              onClick={() => window.open("/user-guide", "_blank")}
            />
          </Tippy>
        </div>
      </div>

      <SlideoverModal
        size="lg"
        visible={raiseTicket}
        onClose={() => {
          setRaiseTicket(false);
        }}
        headerTitle={`Report an Issue`}
      >
        <div className="h-[100%]">
          <iframe
            src={`https://cytin.online/forms/ticket`}
            width="100%"
            height="100%"
          />
        </div>
      </SlideoverModal>
    </>
  );
};

const mapStateToProps = ({ authUser }: RootState) => ({ authUser });

const mapDispatchToProps = (dispatch: any) => ({
  handleLogin: (payload: any) => dispatch(login(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
