import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "src/base-components/Button";
import {
  FormHelp,
  FormInput,
  FormLabel,
  FormTextarea,
} from "src/base-components/Form";
import Lucide from "src/base-components/Lucide";
import ReactSelect from "src/base-components/ReactSelect";
import SlideoverModal from "src/components/SlideoverModal";
import { queryDataProps } from "src/interfaces";
import { fetchCustomerDetail, resolveThread } from "src/services";
import { RootState } from "src/stores";

function ResolveChat({ isOpen, onClose, closeParent, thread, authUser }) {
  const [isExisting, setIsExisting] = useState<any>("");
  const [selectedQuery, setSelectedQuery] = useState<any>("");
  const [queries, setQueries] = useState<any>([]);
  const [searchBy, setSearchBy] = useState<any>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [queriesList, setQueriesList] = useState<any>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [remarks, setRemarks] = useState<string>("");
  const [searchingCustomer, setSearchingCustomer] = useState<boolean>(false);

  const navigate = useNavigate();

  const typeOptions = [
    { label: "Existing Query", value: "existing" },
    { label: "New Query", value: "new" },
  ];

  const searchOptions = [
    { label: "Phone", value: "phone" },
    { label: "Email", value: "email" },
  ];

  useEffect(() => {
    if (isExisting === "new") {
      navigate("/create-query");
      onClose();
      closeParent();
    }
  }, [isExisting]);

  const onSubmit = async () => {
    try {
      setSubmitting(true);

      const query: queryDataProps = queries?.find(
        (item: any) => item?.id === selectedQuery
      );

      if (!query || !thread) {
        throw new Error("Missing required data for the payload.");
      }

      const payload = {
        referenceId: thread?.referenceId,
        threadId: thread?.id,
        customerId: query?.customer_id,
        queryId: query?.id,
        agentId: authUser?.id,
        remarks: remarks,
      };

      if (!payload) {
        throw new Error("Payload is invalid.");
      }

      await resolveThread(payload);

      onClose();
      closeParent();
    } catch (error: any) {
      console.error("Error resolving thread:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const searchCustomer = async () => {
    try {
      setSearchingCustomer(true);
      if (!searchValue && !searchBy) {
        alert("Please provide Value for search");
        return;
      }
      await fetchCustomerDetail({ search_by: searchBy, value: searchValue })
        .then((response: any) => {
          if (response) {
            setQueries(response);
            const payload = response?.map((item: any) => ({
              label: item?.query_no,
              value: item?.id,
            }));
            if (payload) {
              setQueriesList(payload);
            }
          }
        })
        .catch((error: any) => {
          console.log("Error : ", error);
        });
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setSearchingCustomer(false);
    }
  };

  const resetValues = () => {
    setIsExisting("");
    setSearchBy("");
    setSearchValue("");
    setQueriesList([]);
  };

  return (
    <SlideoverModal
      size={"sm"}
      headerTitle={"Resolve"}
      visible={isOpen}
      className="p-3"
      onClose={(value) => {
        onClose();
        resetValues();
      }}
    >
      <div className="h-full w-full flex flex-col">
        <div className="w-full mt-3">
          <FormLabel>Query Type</FormLabel>
          <ReactSelect
            options={typeOptions}
            placeholder={"Query Type"}
            value={isExisting}
            required
            isSearchable={false}
            onChange={(_, value) => {
              setIsExisting(value as any);
            }}
          />
        </div>

        {isExisting === "existing" && (
          <>
            <div className="w-full mt-3">
              <FormLabel>Search by</FormLabel>
              <ReactSelect
                options={searchOptions}
                placeholder={"Select"}
                value={searchBy}
                required
                isSearchable={false}
                onChange={(_, value) => {
                  setSearchBy(value as any);
                }}
              />
            </div>
            {searchBy && (
              <div className="w-full mt-3">
                <FormLabel className="w-full ">
                  {searchBy === "phone" ? "Phone" : "Email"}
                </FormLabel>
                <FormInput
                  value={searchValue}
                  required
                  inputMode={searchBy === "phone" ? "numeric" : "email"}
                  type="text"
                  onChange={(event: any) => {
                    setSearchValue(event?.target?.value);
                  }}
                />

                {queriesList?.length == 0 && (
                  <Button
                    type="button"
                    className="w-full px-10 md:w-auto text-white bg-primary  ml-auto mt-3 "
                    disabled={searchingCustomer}
                    loading={searchingCustomer}
                    onClick={() => searchCustomer()}
                  >
                    Search
                  </Button>
                )}
              </div>
            )}

            {queriesList?.length > 0 && (
              <div className="w-full mt-3">
                <FormLabel>Queries</FormLabel>
                <ReactSelect
                  options={queriesList}
                  placeholder={"Select Query"}
                  value={selectedQuery}
                  onChange={(_, value) => {
                    setSelectedQuery(value as any);
                  }}
                />
              </div>
            )}
          </>
        )}
        <div className="flex-1 w-full mt-3 ">
          <FormLabel>Remarks</FormLabel>
          <FormTextarea
            rows={5}
            value={remarks || ""}
            name="remark"
            required
            onChange={(event: any) => setRemarks(event?.target?.value)}
          />
          <FormHelp className="text-right">
            Maximum characters: {remarks ? remarks.length : 0}
            /2000
          </FormHelp>
        </div>

        {selectedQuery && (
          <Button
            className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto "
            type="button"
            onClick={onSubmit}
            disabled={submitting || !remarks}
            loading={submitting}
          >
            <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-2 -ml-2" />
            Submit
          </Button>
        )}
      </div>
    </SlideoverModal>
  );
}

const mapStateToProps = ({ authUser }: RootState) => ({
  authUser: authUser,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ResolveChat);
