import { permissionAPIUrls } from "../common/apiActions";
import { fetch as http } from "./http";

export const fetchPermissionsService = async (
  { type = 0 }: { type: 0 | 1 } = { type: 0 }
) => {
  try {
    const data = await http.Get(`${permissionAPIUrls?.get}?type=${type}`);
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const createPermissionService = async (payload: any) => {
  try {
    const data = await http.Post(permissionAPIUrls?.create, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const updatePermissionService = async (payload: any) => {
  try {
    const data = await http.Post(permissionAPIUrls?.update, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};
