import { convertToForm, convertToFormMultipleFiles } from "src/helpers";
import { messagesAPIUrls } from "../common/apiActions";
import { fetch as http } from "./http";

export const fetchMessagesService = async () => {
  try {
    const data = await http.Get(messagesAPIUrls?.get);
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const createMessageService = async (payload: any) => {
  try {
    const data = await http.Post(messagesAPIUrls?.create, payload, "Multiple");
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const updateMessageService = async (payload: any) => {
  try {
    const data = await http.Post(messagesAPIUrls?.update, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};
