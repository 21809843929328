export const FETCH_QUERY_LIST = "FETCH_QUERY_LIST";
export const FETCH_USER_QUERY_HISTORY = "FETCH_USER_QUERY_HISTORY";
export const FETCH_QUERY = "FETCH_QUERY";
export const CREATE_QUERY = "CREATE_QUERY";
export const UPDATE_QUERY = "UPDATE_QUERY";
export const FETCH_QUERY_ACTIVITY_LOG_LIST = "FETCH_QUERY_ACTIVITY_LOG_LIST";
export const CREATE_QUERY_ACTIVITY_LOG = "CREATE_QUERY_ACTIVITY_LOG";
export const QUERY_ASSIGN_TO = "QUERY_ASSIGN_TO";
export const RE_ASSIGN = "RE_ASSIGN";
export const FORCE_CLOSE_QUERY = "FORCE_CLOSE_QUERY";
export const CALL_BACK_QUERY = "CALL_BACK_QUERY";
export const VIEW_PERMISSION_ACTION = "VIEW_PERMISSION_ACTION";
export const UPDATE_STATUS = "UPDATE_STATUS";
export const FETCH_COLD_QUERY_LIST = "FETCH_COLD_QUERY_LIST";
