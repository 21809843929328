import { chatAPIUrls } from "../common/apiActions";
import { fetch as http } from "./http";

export const fetchCustomerDetail = async (payload: any) => {
  try {
    const response = await http.Post(`${chatAPIUrls?.getCustomer}`, payload);
    return response?.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const resolveThread = async (payload: any) => {
  try {
    const response = await http.Post(`${chatAPIUrls?.resolveThread}`, payload);
    return response;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const assignThread = async (payload: any) => {
  try {
    const response = await http.Post(`${chatAPIUrls?.assignThread}`, payload);
    return response;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const fetchChatHistory = async (payload: any) => {
  try {
    const response = await http.Post(`${chatAPIUrls?.getChatHistory}`, payload);
    return response;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const updateChatNameService = async (payload: any) => {
  try {
    const data = await http.Post(chatAPIUrls?.updateChat, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};
