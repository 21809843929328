import { fetch as http } from "./http";
import { queryAPIUrls } from "../common/apiActions";
import { store } from "src/stores";
import { apiBaseUrl as baseUrl } from "../services/http";
import { handleLoadQuery } from "src/stores/query";

export const fetchQueriesService = async () => {
  try {
    const data = await http.Get(queryAPIUrls?.get);
    return data;
  } catch (error: any) {
    throw error;
  }
};

export const fetchColdQueriesService = async () => {
  try {
    const data = await http.Get(queryAPIUrls?.coldFlowList);
    return data;
  } catch (error: any) {
    throw error;
  }
};

export const fetchQueryQueuesService = async () => {
  try {
    const data = await http.Get(queryAPIUrls?.getQueues);
    return data;
  } catch (error: any) {
    throw error;
  }
};

export const updateQueryQueueService = async (payload: any) => {
  try {
    const data = await http.Post(queryAPIUrls?.updateQueue, payload);
    return data;
  } catch (error: any) {
    throw error;
  }
};

export const createQueryService = async (payload: any) => {
  try {
    const data = await http.Post(queryAPIUrls?.create, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const updateQueryService = async (payload: any) => {
  try {
    const data = await http.Post(queryAPIUrls?.update, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const fetchQueryService = async (payload: {
  id: string;
  callback?: Function;
}) => {
  try {
    const data = await http.Get(`${queryAPIUrls?.getById}?id=${payload?.id}`);
    const activityLogPaginate = {
      current_page: 1,
      data: data?.activity_log || [],
      first_page_url: `${baseUrl}/queries/get-activity-log/9b657549-3b12-4c37-bea3-27ad01b6b902?page=1`,
      from: null,
      last_page: 2,
      last_page_url: `${baseUrl}/queries/get-activity-log/9b657549-3b12-4c37-bea3-27ad01b6b902?page=1`,
      links: [
        {
          url: null,
          label: "&laquo; Previous",
          active: false,
        },
        {
          url: `${baseUrl}/queries/get-activity-log/9b657549-3b12-4c37-bea3-27ad01b6b902?page=1`,
          label: "1",
          active: true,
        },
        {
          url: null,
          label: "Next &raquo;",
          active: false,
        },
      ],
      next_page_url: null,
      path: `${baseUrl}/queries/get-activity-log/9b657549-3b12-4c37-bea3-27ad01b6b902`,
      per_page: 20,
      prev_page_url: null,
      to: null,
      total: 0,
    };
    return { ...data, activity_log: activityLogPaginate };
  } catch (error: any) {
    throw new Error(error);
  }
};

export const fetchQueryHistoryService = async (payload: any) => {
  try {
    const data = await http.Post(queryAPIUrls?.userQueries, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const fetchQueryActivityLogService = async (id: string) => {
  try {
    const previous = store?.getState()?.queries?.detail?.activity_log;
    const currentPage =
      previous?.data && previous?.data?.length > 0 ? previous?.current_page : 0;
    let data = await http.Get(
      `${queryAPIUrls?.getActivities}/${id}?page=${currentPage + 1}`
    );
    data = {
      ...data,
      isAllLoaded: data?.data?.length < 20,
      data: [...(previous?.data || []), ...data?.data],
    };
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const createQueryActivityLogService = async (payload: any) => {
  try {
    const data = await http.Post(queryAPIUrls?.createActivity, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const queryAssignToService = async (payload: any) => {
  try {
    const data = await http.Post(queryAPIUrls?.assign, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const queryReAssignService = async (payload: any) => {
  try {
    const data = await http.Post(queryAPIUrls?.reAssign, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const queryBillingDocsService = async (payload: any) => {
  try {
    const data = await http.Post(queryAPIUrls?.billingDocs, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const forceCloseQuery = async (payload: any) => {
  try {
    const data = await http.Post(queryAPIUrls?.forceClose, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const callBackQuery = async (payload: any) => {
  try {
    const data = await http.Post(queryAPIUrls?.callBack, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const viewPermissionActionService = async (payload: any) => {
  try {
    const data = await http.Post(queryAPIUrls?.viewPermission, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const updateQueryQue = async (payload: any) => {
  try {
    const data = await http.Post(queryAPIUrls?.queriesQue, payload, "Multiple");
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const updateQueryStatus = async (payload: any) => {
  try {
    const data = await http.Post(queryAPIUrls?.updateStatus, payload);
    return data;
  } catch (error: any) {
    payload?.callback && payload?.callback(true);
    delete payload?.callback;
    throw new Error(error);
  }
};

export const exportQueryData = async (payload: any) => {
  try {
    const { agent, startDate, endDate, agentName } = payload;

    const url = `${queryAPIUrls?.exportQueries}?from_date=${startDate}&to_date=${endDate}&sourced_by=${agent}`;

    const data = await http.ExportGet(url);
    if (data) {
      const url = window.URL.createObjectURL(data);
      const a = document.createElement("a");
      a.href = url;
      a.download = agentName
        ? `${agentName?.label}.xlsx`
        : `${startDate}_${endDate}.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    }
    return data;
  } catch (error: any) {
    console.log("ERROR: ", error);
    throw new Error(error);
  }
};
