import {
  FETCH_MESSAGES,
  CREATE_MESSAGES,
  UPDATE_MESSAGES,
  FETCH_CHAT_HISTORY,
} from "./types";

export const fetchMessages = (payload?: any) => {
  return {
    type: FETCH_MESSAGES,
    payload,
  };
};

export const createMessage = (payload: any) => {
  return {
    type: CREATE_MESSAGES,
    payload,
  };
};

export const updateMessage = (payload: any) => {
  return {
    type: UPDATE_MESSAGES,
    payload,
  };
};

export const fetchChatHistory = (payload: any) => {
  return {
    type: FETCH_CHAT_HISTORY,
    payload,
  };
};
