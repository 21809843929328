import { call, put, takeLatest } from "redux-saga/effects";
import { CREATE_MESSAGES, UPDATE_MESSAGES, FETCH_CHAT_HISTORY } from "./types";
import { fetchChatHistory } from "src/services";
import { setChatHistory } from "./slice";
import { setAlert } from "../basicSlices";

function* handleFetchChatHistory(
  { payload }: { payload?: any } = { payload: undefined }
): Generator<any> {
  try {
    let responseData: any = yield call(fetchChatHistory, payload);
    if (Array.isArray(responseData)) {
      responseData = responseData[0];
    }
    yield put(setChatHistory(responseData));
  } catch (error: any) {
    console.log("Error: ", error);
    // yield put(
    //   setAlert({
    //     text: error?.message,
    //     type: "danger",
    //     title: "Failed to Load Chats!",
    //   })
    // );
  } finally {
    payload?.callback && payload?.callback();
  }
}

function* handleCreatePermission({
  payload,
}: {
  payload: any;
}): Generator<any> {
  // try {
  //   const responseData: any = yield call(createPermissionService, payload);
  //   // yield handleLoadPermissions();
  // } catch (error: any) {
  //   yield put(
  //     setAlert({
  //       text: error?.message,
  //       type: "danger",
  //       title: "Failed to create Permission!",
  //     })
  //   );
  // } finally {
  //   payload?.callback && payload?.callback();
  // }
}

function* handleUpdatePermission({
  payload,
}: {
  payload: any;
}): Generator<any> {
  // try {
  //   const responseData: any = yield call(updatePermissionService, payload);
  //   // yield handleLoadPermissions();
  // } catch (error: any) {
  //   yield put(
  //     setAlert({
  //       text: error?.message,
  //       type: "danger",
  //       title: "Failed to update Permission!",
  //     })
  //   );
  // } finally {
  //   payload?.callback && payload?.callback();
  // }
}

export default function* permissionSaga() {
  yield takeLatest<any>(FETCH_CHAT_HISTORY, handleFetchChatHistory);
  yield takeLatest<any>(CREATE_MESSAGES, handleCreatePermission);
  yield takeLatest<any>(UPDATE_MESSAGES, handleUpdatePermission);
}
