import React from "react";
import { Slideover } from "../../base-components/Headless";
import Lucide from "../../base-components/Lucide";

interface componentProps {
  headerTitle?: string;
  visible: boolean;
  onClose?: (value: boolean) => void;
  children?: React.ReactNode;
  className?: string;
  style?: any;
  size?: "xl" | "lg" | "md" | "sm";
  backdrop?: boolean;
  headerRight?: React.ReactNode;
  headerLeft?: React.ReactNode;
}

function SlideoverModal(props: componentProps) {
  const {
    visible,
    onClose = () => {},
    headerTitle,
    headerLeft = "",
    children,
    className = "",
    style = {},
    size = "md",
    backdrop = true,
    headerRight = "",
  } = props;
  return (
    <div>
      <Slideover
        size={size}
        open={visible}
        onClose={backdrop ? onClose : () => {}}
      >
        <Slideover.Panel className="w-full rounded-[0.55rem_0_0_0.55rem/1.1rem_0_0_1.1rem]">
          <a
            href=""
            className="focus:outline-none hover:bg-white/10 bg-white/5 transition-all hover:rotate-180 absolute inset-y-0 left-0 right-auto flex items-center justify-center my-5 -ml-[60px] sm:-ml-[105px] border rounded-full text-white/90 w-8 h-8 sm:w-14 sm:h-14 border-white/90 hover:scale-105"
            onClick={(e) => {
              e.preventDefault();
              onClose(false);
            }}
          >
            <Lucide className="w-8 h-8 stroke-[1]" icon="X" />
          </a>
          <Slideover.Title className="px-6 py-5">
            <div className="flex flex-row justify-between w-full">
              {headerLeft ? (
                headerLeft
              ) : (
                <h2 className="text-base font-medium">
                  {headerTitle || "Modal"}
                </h2>
              )}

              {headerRight}
            </div>
          </Slideover.Title>
          <Slideover.Description
            style={style}
            className={`px-6 py-4 ${className}`}
          >
            {children}
          </Slideover.Description>
        </Slideover.Panel>
      </Slideover>
    </div>
  );
}

export default SlideoverModal;
