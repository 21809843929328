import React from "react";
import { Helmet } from "react-helmet";

function MillwoodsDocs() {
  return (
    <div className="w-full h-screen flex justify-center items-center bg-gray-100 p-4">
      <Helmet>
        <meta charSet="utf-8" />
        <title>CRM User Guide | Millwoods Travels</title>
      </Helmet>
      <div className="w-full h-full max-w-4xl p-4 bg-white shadow-lg rounded-lg">
        <iframe
          src="https://docs.google.com/document/d/e/2PACX-1vS7IZ8T_lFkkM6N7f9MKfoN7aL2PgDtE9HQ5R9lbEAyhOrlOK0k548drOuDfSq7hfTBNweE9SY_zZPH/pub?embedded=true"
          className="w-full h-full"
        ></iframe>
      </div>
    </div>
  );
}

export default MillwoodsDocs;
