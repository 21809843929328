import { call, put, takeLatest } from "redux-saga/effects";
import { CREATE_LOCATION, FETCH_LOCATION_LIST, UPDATE_LOCATION } from "./types";
import {
  createLocationService,
  fetchLocationsService,
  updateLocationService,
} from "../../services";
import { setLocations } from "./slice";
import { setAlert } from "../basicSlices";

function* handleLoadLocations(
  { payload }: { payload?: any } = { payload: undefined }
): Generator<any> {
  try {
    const responseData: any = yield call(fetchLocationsService);
    payload?.callback && payload?.callback(responseData);

    yield put(setLocations(responseData));

  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to fetch Locations!",
      })
    );
    payload?.callback && payload?.callback(true);
  }
}

function* handleCreateLocation({ payload }: { payload: any }): Generator<any> {
  try {
    const responseData: any = yield call(createLocationService, payload);
    // yield handleLoadLocations();
  } catch (error: any) {
    console.log("HERER", error);
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to add new Location!",
      })
    );
  } finally {
    payload?.callback && payload?.callback();
  }
}

function* handleUpdateLocation({ payload }: { payload: any }): Generator<any> {
  try {
    const responseData: any = yield call(updateLocationService, payload);
    // yield handleLoadLocations();
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to update the Location!",
      })
    );
  } finally {
    payload?.callback && payload?.callback();
  }
}

export default function* locationSaga() {
  yield takeLatest<any>(FETCH_LOCATION_LIST, handleLoadLocations);
  yield takeLatest<any>(CREATE_LOCATION, handleCreateLocation);
  yield takeLatest<any>(UPDATE_LOCATION, handleUpdateLocation);
}
