import { connect } from "react-redux";
import Button from "src/base-components/Button";
import _ from "lodash";
import { PreviewComponent } from "src/base-components/PreviewComponent";
import {
  FormInput,
  FormLabel,
  FormTextarea,
  InputGroup,
} from "src/base-components/Form";
import { FormSwitch } from "src/base-components/Form";
import { useEffect, useMemo, useState } from "react";
import Lucide from "src/base-components/Lucide";
import UploadTicket from "../TicketPdf/UploadTicket";
import TicketPdf from "../TicketPdf";
import {
  acceptQuotationProps,
  createUpdateQuotationProps,
  flightSegmentProps,
  queryDataProps,
  quotationDataProps,
  uploadTicketProps,
  parsedPnrProps,
  pnrParserProps,
  alertProps,
  paymentProps,
  sendTicketProps,
} from "src/interfaces";
import { RootState } from "src/stores";
import { fetchCityAirportDictionaries, parsePNR } from "src/stores/cityAirport";
import DataTable from "src/components/DataTable";
import { acceptQuotation, createQuotation } from "src/stores/quotation";
import LoadingIcon from "src/base-components/LoadingIcon";
import SendQuotation from "./SendQuotation";
import { setAlert } from "src/stores/basicSlices";
import {
  checkAndModifyImageUrl,
  checkPermission,
  separateDateTime,
} from "src/helpers";
import { paymentAPIUrls, quotationAPIUrls } from "src/common/apiActions";
import Card from "src/components/Card";
import AddUpdatePayment from "../Payments/AddUpdate";
import moment from "moment";
import {
  isQueryCancelled,
  isQueryClosed,
  isQueryClosedAndCancelled,
  isQueryOpen,
} from "../../helpers";
import CustomTippy from "src/base-components/customTooltip";
import CustomDialog from "src/base-components/CustomDialog";
import SendTicket from "../TicketPdf/SendTicket";

const Component = ({
  loading,
  quotations = [],
  query,
  onCreate,
  onAccept,
  parsePNR,
  setAlert,
  totalPassengers,
  isViewQuery = false,
}: {
  loading: boolean;
  quotations: quotationDataProps[];
  query: queryDataProps | undefined;
  onCreate: (payload: createUpdateQuotationProps) => void;
  onAccept: (payload: acceptQuotationProps) => void;
  parsePNR: (payload: { pnr: string; callback: Function }) => void;
  setAlert: (payload: alertProps) => void;
  totalPassengers: any;
  isViewQuery: boolean;
}) => {
  const query_id = query?.id || "";
  const [isParsing, setIsParsing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isMarking, setIsMarking] = useState(false);
  const [isDetailsVisible, setIsDetailsVisible] = useState("");
  const [editItinerary, setEditItinerary] = useState("");
  const [itineraryPasted, setItineraryPasted] = useState(false);
  const [quotationModal, setQuotationModal] = useState(false);
  const [uploadTicketModal, setUploadTicketModal] = useState(false);
  const [sendTicketModal, setSendTicketModal] = useState(false);
  const [uploadTicketModel, setUploadTicketModel] = useState<uploadTicketProps>(
    {
      quotation_id: "",
      query_id: query?.id,
      ticket: null,
      ticket_name: "",
    }
  );
  const [sendTicketModel, setSendTicketModel] = useState<sendTicketProps>({
    query_id: query?.id,
    quotation_id: "",
    email_content: "",
    template_id: "",
    cc_email: "",
  });
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentModel, setPaymentModel] = useState<paymentProps>({
    query_id: query_id,
  } as any);
  const [quotationModel, setQuotationModel] = useState<quotationDataProps>({
    query_id: query_id,
    id: "",
  } as any);
  const [addNewProposal, setAddNewProposal] = useState(false);
  const [model, setModel] = useState<createUpdateQuotationProps>({
    query_id: query_id,
    itinerary_json: {} as any,
    itinerary_detail: "",
    actual_price: 0,
    quoted_price: 0,
    quotation_code: "",
    adult_actual_price: 0, //
    adult_quoted_price: 0,
    children_actual_price: 0,
    children_quoted_price: 0,
    infant_actual_price: 0,
    infant_quoted_price: 0,
  });
  const [editModel, setEditModel] = useState<createUpdateQuotationProps>({
    query_id: query_id,
    itinerary_json: {} as any,
    itinerary_detail: "",
    actual_price: 0,
    quoted_price: 0,
    quotation_code: "",
    adult_actual_price: 0,
    adult_quoted_price: 0,
    children_actual_price: 0,
    children_quoted_price: 0,
    infant_actual_price: 0,
    infant_quoted_price: 0,
  });

  const [passengerData, setPassengerData] = useState<any>([]);
  const [passengersName, setPassengersName] = useState<any>([]);
  const [buttonModalPreview, setButtonModalPreview] = useState<boolean>(false);
  const [modalDescription, setModalDescription] = useState("");
  const [isUndefined, setIsUndefined] = useState<boolean>(false);
  const [ticketLimit, setTicketLimit] = useState(false);
  const [priceObject, setPriceObject] = useState<any>([]);

  const handleAddQuotationClick = () => {
    setAddNewProposal(true);
    setIsDetailsVisible("");
  };

  const handleChevronClick = (id) => {
    if (isDetailsVisible == id) {
      setEditItinerary("");
      setIsDetailsVisible("");
      return;
    }
    setIsDetailsVisible(id);
  };

  const generatePriceObject = (prices) => {
    const priceMappings = {
      adult_actual_price: { title: "Adult", type: "actual" },
      children_actual_price: { title: "Child", type: "actual" },
      infant_actual_price: { title: "Infant", type: "actual" },
      adult_quoted_price: { title: "Adult", type: "quoted" },
      children_quoted_price: { title: "Child", type: "quoted" },
      infant_quoted_price: { title: "Infant", type: "quoted" },
    };

    const order = ["Adult", "Child", "Infant"];

    const result: any = { actualPrice: [], quotedPrice: [] };

    const tempActualPrice = {};
    const tempQuotedPrice = {};

    for (let key in prices) {
      if (priceMappings[key]) {
        const { title, type } = priceMappings[key];
        if (type === "actual") {
          tempActualPrice[title] = parseFloat(prices[key]);
        } else if (type === "quoted") {
          tempQuotedPrice[title] = parseFloat(prices[key]);
        }
      }
    }

    result.actualPrice = order.map((title) => ({
      title,
      price: tempActualPrice[title] || 0,
    }));
    result.quotedPrice = order.map((title) => ({
      title,
      price: tempQuotedPrice[title] || 0,
    }));

    return result;
  };

  useEffect(() => {
    if (isDetailsVisible) {
      const currentQuotation: any = quotations?.find(
        (item: any) => item?.id === isDetailsVisible
      );

      if (currentQuotation) {
        const result: any = generatePriceObject(currentQuotation);
        if (result) {
          setPriceObject(result);
        }
      }
    }
  }, [isDetailsVisible]);

  const handleCheckboxChange = (id) => {
    if (id == editItinerary) {
      setEditItinerary("");
      return;
    }
    setEditItinerary(id);
  };

  const handleClearItinerary = () => {
    setModel({
      ...model,
      itinerary_detail: "",
      itinerary_json: {} as any,
    });
    setPassengersName([]);
    setItineraryPasted(false);
    setIsUndefined(false);
  };

  const findUndefinedOrEmptyFields = (obj: any) => {
    const result = {};

    Object.keys(obj).forEach((key) => {
      if (key === "dob" || key === "passengers") {
        return;
      }

      const value = obj[key];
      if (value === undefined || value === null) {
        result[key] = value;
      } else if (typeof value === "object" && value !== null) {
        const nestedResult = findUndefinedOrEmptyFields(value);
        if (Object.keys(nestedResult).length > 0) {
          result[key] = nestedResult;
        }
      }
    });

    return result;
  };

  const isEmpty = (obj: any) =>
    obj && typeof obj === "object" && Object.entries(obj).length === 0;

  useEffect(() => {
    if (model?.itinerary_detail) {
      setIsParsing(true);
      parsePNR({
        pnr: model?.itinerary_detail,
        callback: (isError, res: pnrParserProps) => {
          if (isError) {
            setAlert({
              text: "Failed to parse PNR detail",
              type: "danger",
              title: "",
            });
            setIsParsing(false);
            return;
          }
          const flightData = res?.flights;

          const parsedData: parsedPnrProps = {
            passengers: res?.passengers,
            flightSegments: flightData?.map((item) => {
              let transitTime = "";
              const transit_time = item?.transitTime;
              if (
                item?.transitTime &&
                Object?.keys(item?.transitTime).length > 0
              ) {
                transitTime = `${
                  transit_time?.months && transit_time?.months > 0
                    ? transit_time?.months > 1
                      ? transit_time?.months + " months"
                      : transit_time?.months + " month"
                    : ""
                } ${
                  transit_time?.days && transit_time?.days > 0
                    ? transit_time?.days > 1
                      ? transit_time?.days + " days"
                      : transit_time?.days + " day"
                    : ""
                } ${transit_time?.hours || 0} ${
                  transit_time?.hours && transit_time?.hours > 1 ? "hrs" : "hr"
                } ${transit_time?.minutes || 0} ${
                  transit_time?.minutes && transit_time?.minutes > 1
                    ? "mins"
                    : "min"
                }`;
              }

              return {
                depart_airport: item?.departingFrom?.airportName,
                depart_date: separateDateTime(
                  item?.departingFrom?.time,
                  item?.departingFrom?.timezone
                )?.date,
                depart_time: separateDateTime(
                  item?.departingFrom?.time,
                  item?.departingFrom?.timezone
                )?.time,
                arrive_airport: item?.arrivingAt?.airportName,
                arrive_date: separateDateTime(
                  item?.arrivingAt?.time,
                  item?.arrivingAt?.timezone
                )?.date,
                arrive_time: separateDateTime(
                  item?.arrivingAt?.time,
                  item?.arrivingAt?.timezone
                )?.time,
                airline: item?.iataCode,
                airlineName: item?.airlineName,
                flight_no: item?.flightNumber,
                airlineLogo: checkAndModifyImageUrl(item?.airlineLogo),
                transit_time: transitTime,
              };
            }),
          };

          const isValid = findUndefinedOrEmptyFields(parsedData);
          if (!isEmpty(isValid)) {
            setModalDescription(
              "Could you please double-check the PNR you provided? It seems there are some inconsistencies. Thank you!"
            );
            setIsUndefined(true);
            setButtonModalPreview(true);
          }
          setModel({ ...model, itinerary_json: parsedData });
          setIsParsing(false);
        },
      });
    } else {
      setIsParsing(false);
    }
  }, [model?.itinerary_detail]);

  const flightSegments: flightSegmentProps[] = Array.isArray(
    model?.itinerary_json?.flightSegments
  )
    ? model?.itinerary_json?.flightSegments
    : [];

  const calculatedValues = useMemo(() => {
    let actualPrice = 0;
    let quotedPrice = 0;

    passengerData.forEach((passenger: any) => {
      const key = passenger.key;
      const number = passenger.number;

      actualPrice +=
        parseFloat(model?.[`${key?.toLowerCase()}_actual_price`] || 0) * number;

      quotedPrice +=
        parseFloat(model?.[`${key?.toLowerCase()}_quoted_price`] || 0) * number;
    });

    return { actualPrice, quotedPrice };
  }, [model, passengerData]);

  const checkIfPaxIsEqual = () => {
    if (passengersName?.length > 0 && totalPassengers) {
      if (passengersName?.length !== totalPassengers) {
        setModalDescription(
          "The numbers of Passengers do not match with PAX. Kindly verify and update."
        );
        setButtonModalPreview(true);
      } else {
        onCreateQuotation();
      }
      return;
    } else {
      setModalDescription(
        "It appears there are no passenger details in the itinerary. Please ensure all passenger information is included before proceeding."
      );
      setIsUndefined(true);
      setButtonModalPreview(true);
    }
  };

  const dialogeOnSave = () => {
    onCreateQuotation();
    setIsUndefined(false);
    setButtonModalPreview(false);
  };

  const onCreateQuotation = () => {
    if (
      !model?.itinerary_detail ||
      !calculatedValues?.actualPrice ||
      !calculatedValues?.quotedPrice
    ) {
      setAlert({
        title: "Failed to create Quotation!",
        text: "Please fill all required detail",
        type: "danger",
      });
      return;
    }
    if (!model?.itinerary_json) {
      setAlert({
        title: "Failed to create Quotation!",
        text: "Please enter valid PNR detail",
        type: "danger",
      });
      return;
    }
    setSubmitting(true);
    const payload = {
      ...model,
      actual_price: calculatedValues?.actualPrice,
      quoted_price: calculatedValues?.quotedPrice,
      passengers: passengersName,
      confirmed: passengersName?.length !== totalPassengers ? false : true,
    };

    onCreate({
      ...payload,
      callback: (error) => {
        setSubmitting(false);
        setAddNewProposal(error);
      },
    } as any);
  };
  const itineraryJsonColumns = [
    {
      title: "Carrier/ Flight",
      field: "",
      cell: (item: flightSegmentProps) => {
        if (Object?.keys(item).length == 1) {
          return null;
        }
        return (
          <label className="">
            <div className="text-left">
              <div className="flex items-center">
                <img
                  className="h-20 w-20 rounded-full ml-1 p-1 object-contain	"
                  src={item.airlineLogo}
                  alt="flight"
                />
              </div>
              <div className="mt-1.5 xl:mt-1 items-center text-primary-600">
                {item?.flight_no}
              </div>
              <div className="mt-1.5 xl:mt-1 items-center text-black-900">
                {item?.airlineName}
              </div>
            </div>
          </label>
        );
      },
    },
    {
      title: "Departing",
      field: "",
      cell: (item: flightSegmentProps) => {
        if (Object?.keys(item).length == 1) {
          return null;
        }
        return (
          <div className="">
            <div>{item?.depart_airport}</div>
            <div className="flex items-center text-primary mt-2">
              <Lucide icon="Timer" className="mr-1 w-4 h-4 text-black" />{" "}
              {moment(item?.depart_date).format("DD MMM YY")} @{" "}
              {moment(item?.depart_time, "HH:mm").format("hh:mm A")}
            </div>
          </div>
        );
      },
    },
    {
      title: "",
      field: "",
      hide: (item) => {
        return Object?.keys(item).length > 1;
      },
      cell: (item: flightSegmentProps) => {
        return (
          <div
            style={{ left: 0, right: 0, top: 5, bottom: 0 }}
            className="absolute w-100 text-center"
          >
            {item?.transit_time && (
              <div className="font-semibold">Layover {item?.transit_time}</div>
            )}
          </div>
        );
      },
    },
    {
      title: "Arriving",
      field: "",
      cell: (item: flightSegmentProps) => {
        if (Object?.keys(item).length == 1) {
          return null;
        }
        return (
          <div className="">
            <div>{item?.arrive_airport}</div>
            <div className="flex items-center text-primary mt-2">
              <Lucide icon="Timer" className="mr-1 w-4 h-4 text-black" />{" "}
              {moment(item?.arrive_date).format("DD MMM YY")} @{" "}
              {moment(item?.arrive_time, "HH:mm").format("hh:mm A")}
            </div>
          </div>
        );
      },
    },
  ];
  const RenderPNRParsedInputData = () => {
    let itineraryJson = flightSegments || [];
    if (!Array.isArray(itineraryJson)) {
      itineraryJson = [];
    }
    let itineraryJsonData: flightSegmentProps[] = [];
    itineraryJson?.map((item) => {
      itineraryJsonData?.push(item);
      if (item?.transit_time) {
        itineraryJsonData?.push({
          transit_time: item?.transit_time,
        } as any);
      }
    });
    return (
      <div className="mt-5">
        <DataTable
          showColors={false}
          showHeader={false}
          showFooter={false}
          data={itineraryJsonData}
          loading={isParsing}
          columns={itineraryJsonColumns}
          passengersName={passengersName}
          priceObject={priceObject}
        />
      </div>
    );
  };

  const onItineraryKeyDown = (e) => {
    if (itineraryPasted && e.keyCode !== 13) {
      e.preventDefault();
      return;
    }

    var ctrl = e.ctrlKey ? e.ctrlKey : e.keyCode === 17 ? true : false;
    if (
      !(
        (e.keyCode === 86 && ctrl) ||
        (e.keyCode === 67 && ctrl) ||
        (e.keyCode === 88 && ctrl) ||
        (ctrl && e.keyCode == 65) ||
        e.keyCode == 13 ||
        (e.keyCode == 8 &&
          window?.getSelection()?.toString() == model?.itinerary_detail)
      )
    ) {
      e.preventDefault();
    } else {
      setItineraryPasted(true);
    }
  };

  const generateData = (adults: number, infants: number, children: number) => {
    const data = [
      { title: "Adult", number: adults, key: "adult" },
      { title: "Infant", number: infants, key: "infant" },
      { title: "Child", number: children, key: "children" },
    ];

    const filteredData = data.filter((category) => category.number > 0);

    return filteredData;
  };

  useEffect(() => {
    const response: any = generateData(
      query?.no_of_adults || 0,
      query?.no_of_infants || 0,
      query?.no_of_children || 0
    );

    if (response?.length > 0) {
      setPassengerData(response);
    }
  }, [query]);

  const handlePrices = (type: string, name: string, event: any) => {
    const { value } = event.target;

    if (!/^\d*\.?\d*$/.test(value)) {
      return;
    }

    if (value.startsWith("-") || value.startsWith("0")) {
      return;
    }

    const numericValue = parseFloat(value);

    if (type === "actual") {
      setModel((prevModel) => ({
        ...prevModel,
        [`${name.toLowerCase()}_actual_price`]: numericValue,
      }));
    } else {
      setModel((prevModel) => ({
        ...prevModel,
        [`${name.toLowerCase()}_quoted_price`]: numericValue,
      }));
    }
  };

  useEffect(() => {
    if (model?.itinerary_json) {
      const passengers = model?.itinerary_json?.passengers;
      if (passengers && passengers?.length > 0) {
        const payload = passengers?.map((item: any) => {
          return `${item?.type ?? ""}-${item?.name}${
            item?.dob ? `(${item?.dob})` : ""
          }`;
        });
        setPassengersName(payload ?? []);
      }
    }
  }, [model, quotations]);

  const [coldFlowModal, setColdFlowModal] = useState<boolean>(false);
  const [coldFlowQuotationModal, setColdFlowQuotationModal] =
    useState<boolean>(false);
  const [selectedQuotationId, setSelectedQuotationId] = useState<any>(null);
  const [selectedQuotationItem, setSelectedQuotationItem] = useState<any>(null);

  // Mark As Accepted Started
  const coldFlowModalSave = (id: any, isColdFollowUp: boolean) => {
    const payload: acceptQuotationProps = {
      quotation_id: id,
      query_id: query_id,
      isColdFollow: isColdFollowUp,
    };

    onAccept({
      ...payload,
      callback: (error) => {
        if (!error) {
          setColdFlowModal(false);
          setSelectedQuotationId(null);
        }
        setIsMarking(false);
      },
    } as any);
  };

  const handleMarkAsAccepted = (isColdFollowUp: boolean) => {
    coldFlowModalSave(selectedQuotationId, isColdFollowUp);
  };
  // Ending

  const quotationModalSave = () => {
    setQuotationModal(true);
  };

  const handleQuotation = (isColdFollow: boolean) => {
    const payload = {
      ...quotationModel,
      isColdFollow: isColdFollow,
    };
    setQuotationModel(payload);
    setColdFlowQuotationModal(false);
    quotationModalSave();
  };

  return (
    <div>
      <CustomDialog
        buttonModalPreview={buttonModalPreview}
        setButtonModalPreview={setButtonModalPreview}
        description={modalDescription}
        onSave={dialogeOnSave}
        onCancel={() => setButtonModalPreview(false)}
        saveTitle="Proceed anyway"
        isOkayBtn={isUndefined}
      />

      {/* Mark As Accepted */}
      <CustomDialog
        buttonModalPreview={coldFlowModal}
        setButtonModalPreview={setColdFlowModal}
        description={
          "Would you like to update the stage of this query from Cold Follow-Up to In-Process?"
        }
        onSave={() => handleMarkAsAccepted(false)}
        onCancel={() => {
          handleMarkAsAccepted(true);
        }}
        saveTitle="Yes, Change Stage"
        isOkayBtn={false}
        setLoader={setIsMarking}
        cancelTitle="No, Keep Current"
      />

      {/* Send Quotation */}
      <CustomDialog
        buttonModalPreview={coldFlowQuotationModal}
        setButtonModalPreview={setColdFlowQuotationModal}
        description={
          "Would you like to update the stage of this query from Cold Follow-Up to In-Process?"
        }
        onSave={() => handleQuotation(false)}
        onCancel={() => {
          handleQuotation(true);
        }}
        saveTitle="Yes, Change Stage"
        isOkayBtn={false}
        cancelTitle="No, Keep Current"
      />

      <div className="text-base font-medium group-[.mode--light]:text-white mt-8 mb-2">
        Quotation Proposals
      </div>
      <Card className="flex flex-col p-4">
        <PreviewComponent>
          {loading ? (
            <div className="flex w-[100%] justify-center py-16">
              <LoadingIcon
                color="#64748b"
                icon="oval"
                className="w-8 h-8 mx-auto"
              />
            </div>
          ) : (
            <div className="w-full">
              {quotations?.length > 0 &&
                quotations?.map((item, index) => {
                  let itineraryJson =
                    (JSON.parse(item?.itinerary_json) as parsedPnrProps)
                      ?.flightSegments || [];

                  let passengers =
                    (JSON.parse(item?.itinerary_json) as parsedPnrProps)
                      ?.passengers || [];

                  const payload = passengers?.map((item: any) => {
                    if (typeof item === "string") {
                      return item;
                    } else {
                      return `${item?.type ?? ""}-${item?.name}${
                        item?.dob ? `(${item?.dob})` : ""
                      }`;
                    }
                  });

                  itineraryJson?.push({
                    passengers: payload,
                  } as any);

                  if (!Array.isArray(itineraryJson)) {
                    itineraryJson = [];
                  }
                  let itineraryJsonData: flightSegmentProps[] = [];
                  itineraryJson?.map((item) => {
                    itineraryJsonData?.push(item);
                    if (
                      payload &&
                      !itineraryJsonData.some((data) => data.passengers)
                    ) {
                      itineraryJsonData.push({
                        passengers: payload,
                      } as any);
                    }

                    if (item?.transit_time) {
                      itineraryJsonData?.push({
                        transit_time: item?.transit_time,
                      } as any);
                    }
                  });

                  return (
                    <div
                      key={index}
                      className={`mb-3 border rounded-[0.6rem] shadow-sm w-full p-4 ${
                        item?.status == "3" || item?.status == "4"
                          ? "border-green-600/80 bg-green-600/10"
                          : item?.status == "5"
                          ? "border-red-600/80 bg-red-600/10"
                          : "border-slate-300/80"
                      }`}
                    >
                      <>
                        <div className="flex gap-x-5 flex-col flex-wrap justify-between items-start md:flex-row">
                          <div className="max-w-40 min-w-40 mr-2">
                            <div className="font-medium text-slate-600">
                              {item?.quotation_code}
                            </div>
                          </div>
                          <div>
                            <div className="text-slate-500">Actual Price:</div>
                            <div className="mt-1.5 font-medium text-slate-600">
                              ${item?.actual_price}
                            </div>
                          </div>
                          <div>
                            <div className="text-slate-500">Quoted Price:</div>
                            <div className="mt-1.5 font-medium text-slate-600">
                              ${item?.quoted_price}
                            </div>
                          </div>
                          <div>
                            <div className="text-slate-500">Status:</div>
                            <div
                              className={`mt-1.5 font-medium text-slate-600 min-w-32 ${
                                item?.status == "3"
                                  ? "text-green-500"
                                  : "text-black-900"
                              }`}
                            >
                              {item?.status_text}
                            </div>
                          </div>
                          <button
                            className="my-auto px-5 py-3"
                            onClick={() => handleChevronClick(item?.id)}
                          >
                            <Lucide
                              icon={
                                isDetailsVisible == item?.id
                                  ? "ChevronDown"
                                  : "ChevronRight"
                              }
                              className="w-5 h-5 stroke-[1.3]"
                            />
                          </button>
                        </div>
                        {isDetailsVisible == item?.id && (
                          <div className="mt-5">
                            <FormSwitch className="flex flex-wrap justify-items-start w-full sm:w-auto sm:ml-auto sm:mt-0">
                              View Itinerary?
                              <FormSwitch.Label
                                htmlFor="show-example-1"
                                className="ml-0 sm:ml-2"
                              ></FormSwitch.Label>
                              <FormSwitch.Input
                                id="show-example-1"
                                className="ml-3 mr-0"
                                type="checkbox"
                                checked={editItinerary == item?.id}
                                onChange={() => {
                                  handleCheckboxChange(item?.id);
                                  setEditModel({
                                    ...editModel,
                                    quotation_code:
                                      item?.itinerary_detail?.split(" ")?.[0],
                                    itinerary_detail: item?.itinerary_detail,
                                    itinerary_json: {} as any,
                                  });
                                }}
                              />
                            </FormSwitch>
                            {editItinerary == item?.id ? (
                              <FormTextarea
                                value={editModel?.itinerary_detail}
                                onChange={(event) => {
                                  setEditModel({
                                    ...editModel,
                                    quotation_code:
                                      event?.target?.value?.split(" ")?.[0],
                                    itinerary_detail: event?.target?.value,
                                    itinerary_json: {} as any,
                                  });
                                }}
                                onKeyDown={onItineraryKeyDown}
                                className="pr-19 mt-5 resize-none min h-60"
                              />
                            ) : (
                              <div className="mt-5">
                                {itineraryJson?.length > 0 && (
                                  <DataTable
                                    showColors={false}
                                    showHeader={false}
                                    showFooter={false}
                                    data={itineraryJsonData}
                                    loading={isParsing}
                                    columns={itineraryJsonColumns}
                                    passengersName={passengersName}
                                    setPassengersName={setPassengersName}
                                    priceObject={priceObject}
                                  />
                                )}
                                {!isViewQuery && (
                                  <div className="flex  mt-3">
                                    <div className="flex  mt-3 w-full justify-between">
                                      <div
                                        className={
                                          item?.ticket
                                            ? (query?.stage || 0) < 3
                                              ? "flex w-[60%]"
                                              : "flex w-[60%]"
                                            : "flex w-[50%]"
                                        }
                                      >
                                        {query?.accepted_quotation?.length >
                                          0 &&
                                          query?.accepted_quotation?.map(
                                            (value) => {
                                              if (
                                                value?.id?.includes(item?.id)
                                              ) {
                                                return (
                                                  <TicketPdf
                                                    ticket={item?.ticket}
                                                    quotation={item}
                                                    acceptedQuotation={
                                                      query?.accepted_quotation
                                                    }
                                                    onButtonClick={() => {
                                                      if (
                                                        !item?.can_upload_ticket
                                                      ) {
                                                        setAlert({
                                                          title:
                                                            "Can't upload ticket",
                                                          text: "Total paid amount doesn't match with quoted amount",
                                                          type: "warning",
                                                        });
                                                        return;
                                                      }
                                                      setUploadTicketModal(
                                                        true
                                                      );
                                                      setUploadTicketModel({
                                                        quotation_id: item?.id,
                                                      } as any);
                                                    }}
                                                  />
                                                );
                                              }
                                            }
                                          )}
                                      </div>

                                      <CustomDialog
                                        buttonModalPreview={ticketLimit}
                                        setButtonModalPreview={setTicketLimit}
                                        description="You can only upload a maximum of 3 tickets. To upload a new ticket, please delete an existing one."
                                        onCancel={() => setTicketLimit(false)}
                                        isOkayBtn
                                      />

                                      <div className="flex justify-between sm:py-0">
                                        <div className="block">
                                          <div className="flex">
                                            {item?.ticket && (
                                              <>
                                                <Button
                                                  variant="outline-primary"
                                                  onClick={(
                                                    event: React.MouseEvent
                                                  ) => {
                                                    setSendTicketModal(true);
                                                    setSendTicketModel({
                                                      quotation_id: item?.id,
                                                      ticket_type: 1,
                                                    } as any);
                                                  }}
                                                  className="mr-1 border-0 text-primary bg-gray-300 text-sm"
                                                >
                                                  <Lucide
                                                    icon={
                                                      item?.ticket
                                                        ? "Send"
                                                        : "Plus"
                                                    }
                                                    className=" w-4 h-4 mr-1"
                                                  />
                                                  {"Send Ticket"}
                                                </Button>
                                              </>
                                            )}
                                            {checkPermission(
                                              quotationAPIUrls?.accept,
                                              query
                                            ) &&
                                              isQueryOpen(query?.stage) &&
                                              item?.status != "3" &&
                                              item?.status != "4" && (
                                                <Button
                                                  variant="primary"
                                                  className="mr-3 group-[.mode--light]:!bg-white/[0.12] group-[.mode--light]:!text-slate-200 group-[.mode--light]:!border-transparent"
                                                  onClick={() => {
                                                    setSelectedQuotationId(
                                                      item?.id
                                                    );
                                                    if (query?.stage == 5) {
                                                      setColdFlowModal(true);
                                                    } else {
                                                      coldFlowModalSave(
                                                        item?.id,
                                                        false
                                                      );
                                                    }
                                                  }}
                                                  loading={isMarking}
                                                >
                                                  <Lucide
                                                    icon="Check"
                                                    className="stroke-[1.3] w-4 h-4 mr-3"
                                                  />{" "}
                                                  Mark As Accepted
                                                </Button>
                                              )}
                                            {checkPermission(
                                              quotationAPIUrls?.accept,
                                              query
                                            ) &&
                                              ((query?.stage || 0) < 3 ||
                                                (query?.stage || 0) == 5) && (
                                                <Button
                                                  onClick={() => {
                                                    setQuotationModel(item);
                                                    if (query?.stage == 5) {
                                                      setColdFlowQuotationModal(
                                                        true
                                                      );
                                                    } else {
                                                      quotationModalSave();
                                                    }
                                                  }}
                                                  variant="primary"
                                                  className="group-[.mode--light]:!bg-white/[0.12] justify-end group-[.mode--light]:!text-slate-200 group-[.mode--light]:!border-transparent"
                                                >
                                                  <Lucide
                                                    icon="Mail"
                                                    className="stroke-[1.3] w-4 h-4 mr-2"
                                                  />{" "}
                                                  Send Quotation
                                                </Button>
                                              )}
                                            {checkPermission(
                                              paymentAPIUrls?.initiateCancellation
                                            ) &&
                                              (query?.status || 0) == 11 &&
                                              !item?.refund_initiated && (
                                                <span className="">
                                                  <Button
                                                    onClick={() => {
                                                      setPaymentModal(true);
                                                      setPaymentModel({
                                                        query_id,
                                                        quotation_id: item?.id,
                                                        type: 2,
                                                        payment_date: moment(
                                                          new Date()
                                                        ).format("YYYY-MM-DD"),
                                                      } as any);
                                                    }}
                                                    variant="primary"
                                                    className="group-[.mode--light]:!bg-white/[0.12]  group-[.mode--light]:!text-slate-200 group-[.mode--light]:!border-transparent"
                                                  >
                                                    <Lucide
                                                      icon="Mail"
                                                      className="stroke-[1.3] w-4 h-4 mr-2"
                                                    />{" "}
                                                    Initiate Cancellation
                                                  </Button>
                                                  <AddUpdatePayment
                                                    headerTitle="Cancellation payment"
                                                    isPartial={false}
                                                    isView={false}
                                                    loading={false}
                                                    handleAssignToClick={
                                                      {} as any
                                                    }
                                                    visible={paymentModal}
                                                    setVisible={setPaymentModal}
                                                    model={paymentModel}
                                                    setModel={setPaymentModel}
                                                    setAcceptModel={{} as any}
                                                    acceptModel={{} as any}
                                                    store={{} as any}
                                                  />
                                                </span>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    </div>
                  );
                })}
              <div className="w-full py-1">
                {!addNewProposal && quotations?.length === 0 && (
                  <div className="text-lg text-center font-medium flex justify-center text-gray-400 flex-wrap">
                    <p>No quotation proposals added yet</p>
                  </div>
                )}
                {!addNewProposal &&
                  checkPermission(quotationAPIUrls?.create, query) &&
                  (isQueryOpen(query?.stage) || query?.status == 12) && (
                    <div className="flex px-3 py-1">
                      <a
                        className="flex justify-center text-primary mt-2"
                        style={{
                          cursor: "pointer",
                          marginLeft: "auto",
                          marginRight:
                            quotations?.length == 0 ? "auto" : "unset",
                        }}
                        onClick={handleAddQuotationClick}
                      >
                        + Add New Quotation Proposal
                      </a>
                    </div>
                  )}
              </div>
              {addNewProposal && (
                <div className="border border-dashed rounded-[0.6rem] pb-4 pt-2 pl-5 pr-5">
                  <div className="flex flex-col bottom-0">
                    <div className="flex flex-col md:flex-row items-center">
                      <div className="flex flex-col gap-2 items-center mt-2 md:justify-between md:w-full">
                        <div className="w-full mt-2">
                          <FormLabel className="">Actual Price ($):</FormLabel>
                          <div className="flex flex-wrap w-full gap-2">
                            {passengerData?.map((item: any, index: number) => (
                              <div
                                key={index}
                                className="w-full md:w-1/2 lg:w-1/4"
                              >
                                <InputGroup className="w-full">
                                  <InputGroup.Text>
                                    {item?.title}
                                  </InputGroup.Text>
                                  <FormInput
                                    name="actualPrice"
                                    type="number"
                                    value={
                                      model
                                        ? model[
                                            `${item?.key.toLowerCase()}_actual_price`
                                          ] !== 0
                                          ? model[
                                              `${item?.key.toLowerCase()}_actual_price`
                                            ]
                                          : ""
                                        : ""
                                    }
                                    onChange={(event) => {
                                      handlePrices("actual", item?.key, event);
                                    }}
                                  />
                                </InputGroup>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="w-full mt-2">
                          <FormLabel className="">Quoted Price ($):</FormLabel>
                          <div className="flex flex-wrap w-full gap-2">
                            {passengerData?.map((item: any, index: number) => (
                              <div
                                key={index}
                                className="w-full md:w-1/2 lg:w-1/4"
                              >
                                <InputGroup className="w-full">
                                  <InputGroup.Text>
                                    {item?.title}
                                  </InputGroup.Text>
                                  <FormInput
                                    name="quotedprice"
                                    type="number"
                                    value={
                                      model
                                        ? model[
                                            `${item?.key.toLowerCase()}_quoted_price`
                                          ] !== 0
                                          ? model[
                                              `${item?.key.toLowerCase()}_quoted_price`
                                            ]
                                          : ""
                                        : ""
                                    }
                                    onChange={(event) => {
                                      handlePrices("quoted", item?.key, event);
                                    }}
                                  />
                                </InputGroup>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="md:mt-4 w-full md:w-1/2 lg:w-1/3">
                        <div className="w-2/3">
                          <FormLabel className="">
                            Total Actual Price ($):
                          </FormLabel>
                          <FormInput
                            name="totalActualPrice"
                            type="number"
                            disabled={true}
                            value={calculatedValues?.actualPrice}
                          />
                        </div>
                        <div className="mt-4 w-2/3">
                          <FormLabel className="">
                            Total Quoted Price ($):
                          </FormLabel>
                          <FormInput
                            name="totalQuotedPrice"
                            type="number"
                            disabled={true}
                            value={calculatedValues?.quotedPrice}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col relative">
                      {model.itinerary_detail && (
                        <CustomTippy
                          mode="div"
                          content="Clear Itinerary"
                          className="absolute top-6 right-2"
                          theme="light"
                        >
                          <div onClick={handleClearItinerary}>
                            <Lucide icon="X" className="w-6 h-6" />
                          </div>
                        </CustomTippy>
                      )}
                      <FormTextarea
                        placeholder="Enter Itinerary Details"
                        className="mt-5 pr-19 resize-none min h-40"
                        value={model?.itinerary_detail}
                        onChange={(event) => {
                          setModel({
                            ...model,
                            quotation_code:
                              event?.target?.value?.split(" ")?.[0],
                            itinerary_detail: event?.target?.value,
                            itinerary_json: {} as any,
                          });
                        }}
                        onKeyDown={onItineraryKeyDown}
                      />
                    </div>
                    {flightSegments?.length > 0 || isParsing ? (
                      <RenderPNRParsedInputData />
                    ) : null}
                    <div className="flex gap-5 mt-5 justify-end ">
                      <Button
                        type="button"
                        className="bg-primary text-white pl-2 pr-4 w-30 h-7"
                        onClick={checkIfPaxIsEqual}
                        loading={submitting}
                        disabled={isUndefined}
                      >
                        <Lucide
                          icon="Check"
                          color="white"
                          className="stroke-[1.3] w-4 h-4 mr-1"
                        />
                        Save
                      </Button>
                      <Button
                        onClick={() => {
                          setAddNewProposal(false);
                        }}
                        className="bg-gray-300 text-black-800 pl-2 pr-4 w-30 h-7"
                      >
                        <Lucide
                          icon="X"
                          className="stroke-[1.3] w-4 h-4 mr-1"
                        />
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </PreviewComponent>
      </Card>
      <SendQuotation
        visible={quotationModal}
        setVisible={setQuotationModal}
        model={quotationModel}
        setModel={setQuotationModel}
      />
      <UploadTicket
        visible={uploadTicketModal}
        setVisible={setUploadTicketModal}
        model={uploadTicketModel}
        setModel={setUploadTicketModel}
        quotations={quotations}
      />
      <SendTicket
        visible={sendTicketModal}
        setVisible={setSendTicketModal}
        model={sendTicketModel}
        setModel={setSendTicketModel}
        quotations={quotations}
      />
    </div>
  );
};

const mapStateToProps = ({ cityAirports, queries: { detail } }: RootState) => ({
  cityAirports,
  query: detail,
  quotations: detail?.quotations_detail || [],
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchCityAirportDetail: (payload: any) =>
    dispatch(fetchCityAirportDictionaries(payload)),
  onCreate: (payload: any) => dispatch(createQuotation(payload)),
  onAccept: (payload: any) => dispatch(acceptQuotation(payload)),
  parsePNR: (payload: any) => dispatch(parsePNR(payload)),
  setAlert: (payload: any) => dispatch(setAlert(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
