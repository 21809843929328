import { connect } from "react-redux";
import Button from "src/base-components/Button";
import _ from "lodash";
import {
  FormHelp,
  FormInput,
  FormLabel,
  FormSelect,
  InputGroup,
} from "src/base-components/Form";
import React, { useEffect, useState } from "react";
import Lucide from "src/base-components/Lucide";
import SlideoverModal from "src/components/SlideoverModal";
import {
  authUserProps,
  emailTemplateProps,
  queryDataProps,
  quotationDataProps,
  sendQuotationProps,
} from "src/interfaces";
import { RootState } from "src/stores";
import { sendQuotation } from "src/stores/quotation";
import { fetchEmailTemplates } from "src/stores/emailTemplate";
import LoadingIcon from "src/base-components/LoadingIcon";
import TinyMCE from "src/base-components/TinyMCE";

const Component = ({
  query,
  onSend,
  visible,
  setVisible,
  model,
  setModel,
  fetchTemplates,
  emailTemplates,
  authUser,
}: {
  query: queryDataProps | undefined;
  onSend: (payload: sendQuotationProps) => void;
  visible: boolean;
  setVisible: Function;
  model: quotationDataProps;
  setModel: Function;
  fetchTemplates: Function;
  emailTemplates: emailTemplateProps;
  authUser: authUserProps;
}) => {
  const templates = emailTemplates?.data || [];
  const query_id = query?.id || "";
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendOption, setSendOption] = useState<any>(null);
  const [customerNumbers, setCustomerNumbers] = useState<any>([]);
  const [selectedNumber, setSelctedNumber] = useState<any>(null);

  const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setModel({ ...model, [name]: value });
  };

  const handleSendQuotation = (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();

    const greeting = `<p>Dear ${
      model?.customerName ?? query?.customer?.fullname
    },</p>`;

    const contentWithGreeting = greeting + model?.email_content;

    const payload: any = {
      email_content: contentWithGreeting,
      quotation_id: model?.id,
      query_id: model?.query_id || query_id,
      cc_email: model?.cc_email?.replaceAll(" ", ""),
      isColdFollow: model?.isColdFollow ?? false,
      customer_id: query?.customer?.id,
      agent_id: authUser?.id,
      phone: sendOption == 1 || sendOption == 3 ? selectedNumber : null,
      sendOption: sendOption,
    };
    setSubmitting(true);
    onSend({
      ...payload,
      callback: (error) => {
        if (!error) {
          setModel({ query_id: query_id } as any);
          setVisible(false);
        }
        setSubmitting(false);
      },
    } as any);
  };
  useEffect(() => {
    if (visible) {
      setLoading(true);
      fetchTemplates({
        callback: (error) => {
          setLoading(false);
        },
      });
    }
  }, [visible]);

  useEffect(() => {
    setModel((previous) => {
      return {
        ...previous,
        email_content:
          templates?.find((ele) => ele?.id == model?.template_id)?.content ||
          "",
      };
    });
  }, [model?.template_id]);

  useEffect(() => {
    if (query) {
      const customerId = query?.customer?.id;
      const numbersPayload = [];

      if (query?.customer?.isDefault == 0) {
        numbersPayload.push(
          `${query?.customer?.wp_country_code?.replace(/\+/g, "")}${
            query?.customer?.whatsapp_phone
          }` as never
        );
      } else {
        numbersPayload.push(
          `${query?.customer?.country_code?.replace(/\+/g, "")}${
            query?.customer?.phone
          }` as never
        );
      }

      setCustomerNumbers(numbersPayload);
      // handleCustomerNumber(customerId);
    }
  }, [query, visible]);

  const sendingOptions = [
    {
      id: 1,
      label: "Whatsapp",
    },
    {
      id: 2,
      label: "Email",
    },
    {
      id: 3,
      label: "Both",
    },
  ];

  const handleClose = () => {
    setSelctedNumber(null);
    setSendOption(null);
    setCustomerNumbers(null);
  };

  return (
    <SlideoverModal
      size="lg"
      visible={visible}
      onClose={() => {
        setVisible(false);
        handleClose();
      }}
      headerTitle="Send Quotation"
    >
      <form
        onSubmit={handleSendQuotation}
        className="h-[100%] w-full flex flex-col"
      >
        <div className="text-base font-medium group-[.mode--light]:text-white hidden md:block">
          Query # <span className="text-primary">{query?.query_no}</span>
        </div>
        <div className="flex flex-col items-start justify-start sm:flex-row ">
          <div className="text-center mt-5 sm:text-left">
            <div className="text-base font-medium">Actual Price:</div>
            <div className="text-slate-500 mt-0.5 text-sm">
              ${model?.actual_price}
            </div>
          </div>
          <div className="text-center mt-5 m sm:text-left sm:ml-16">
            <div className="text-base font-medium">Quoted Price:</div>
            <div className="text-slate-500 mt-0.5 text-sm">
              ${model?.quoted_price}
            </div>
          </div>
        </div>
        {loading ? (
          <div className="flex w-[100%] justify-center py-12">
            <LoadingIcon className="w-6 h-6 mx-auto" />
          </div>
        ) : (
          <div className="mt-5">
            <div className="flex-1 w-full xl:mt-0">
              <div className="w-full mt-3">
                <FormLabel className="w-full">Send quotation on</FormLabel>
                <FormSelect
                  className="w-full"
                  id="sendOption"
                  name="sendOption"
                  required
                  value={sendOption}
                  onChange={(e: any) => {
                    setSendOption(e.target?.value);
                    setSelctedNumber(null);
                    setModel({ ...model, template_id: "" });
                  }}
                >
                  <option value={""}>Select</option>
                  {sendingOptions.map((data, key) => (
                    <option key={key} value={data?.id}>
                      {data.label}
                    </option>
                  ))}
                </FormSelect>
              </div>

              {(sendOption == 1 || sendOption == 3) && (
                <div className="w-full mt-3">
                  <FormLabel className="w-full">Phone</FormLabel>
                  <FormSelect
                    className="w-full"
                    id="selectedNumber"
                    name="selectedNumber"
                    required
                    value={selectedNumber}
                    onChange={(e: any) => setSelctedNumber(e.target?.value)}
                  >
                    <option value={""}>Select</option>
                    {customerNumbers?.map((data, key) => (
                      <option key={key} value={data}>
                        {data}
                      </option>
                    ))}
                  </FormSelect>
                </div>
              )}

              {(sendOption == 2 || sendOption == 3) && (
                <>
                  <div className="w-full mt-3">
                    <FormLabel className="w-full">CC Emails</FormLabel>
                    <FormInput
                      type="text"
                      name="cc_email"
                      value={model?.cc_email}
                      onChange={(e) =>
                        setModel((previous) => {
                          return {
                            ...previous,
                            cc_email: e?.target?.value,
                          };
                        })
                      }
                      className="w-full"
                      placeholder="Enter emails. e.g. example1@email.com, example2@email.com"
                      aria-label="CC Emails"
                    />
                  </div>
                  <div className="w-full mt-3">
                    <FormLabel className="w-full">Template</FormLabel>
                    <FormSelect
                      className="flex-1 w-full"
                      id="template_id"
                      name="template_id"
                      required
                      value={model?.template_id}
                      onChange={handleSelectChange as any}
                    >
                      <option value={""}>--Select Email Template--</option>
                      {templates.map((template, templateKey) => (
                        <option key={templateKey} value={template?.id}>
                          {template.title}
                        </option>
                      ))}
                    </FormSelect>
                  </div>
                </>
              )}
              {model?.template_id && (
                <div className="mt-3">
                  <FormLabel className="w-full">Email Content</FormLabel>
                  <div className="border rounded-md p-2">
                    <div className="flex-1 w-full">
                      <InputGroup className="w-full">
                        <InputGroup.Text>Dear</InputGroup.Text>
                        <FormInput
                          name="customerName"
                          type="text"
                          required
                          onChange={(e) =>
                            setModel((previous) => {
                              return {
                                ...previous,
                                customerName: e?.target?.value,
                              };
                            })
                          }
                          value={
                            model?.customerName ?? query?.customer?.fullname
                          }
                        />
                      </InputGroup>
                    </div>

                    <div className="mt-3">
                      {/* <FormLabel className="w-full">Content</FormLabel> */}
                      <div className="flex-1 w-full">
                        <TinyMCE
                          value={model?.email_content || ""}
                          onEditorChange={(data) =>
                            setModel((previous) => {
                              return { ...previous, email_content: data };
                            })
                          }
                        />
                        <FormHelp className="text-right">
                          Maximum characters:{" "}
                          {model?.email_content
                            ? model.email_content.length
                            : 0}
                          /2000
                        </FormHelp>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <Button
          disabled={
            !sendOption ||
            (sendOption == 1 && !selectedNumber) ||
            (sendOption == 2 && !model?.template_id)
          }
          variant="primary"
          type="submit"
          loading={submitting}
          className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto"
        >
          <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-3" />
          Submit
        </Button>
      </form>
    </SlideoverModal>
  );
};

const mapStateToProps = ({
  queries: { detail },
  emailTemplates,
  authUser,
}: RootState) => ({
  query: detail,
  emailTemplates,
  authUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  onSend: (payload: any) => dispatch(sendQuotation(payload)),
  fetchTemplates: (payload: any) =>
    dispatch(fetchEmailTemplates({ type: 2, ...payload })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
