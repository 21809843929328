import React, { useEffect, useState } from "react";
import Button from "src/base-components/Button";
import {
  FormHelp,
  FormInput,
  FormLabel,
  FormTextarea,
} from "src/base-components/Form";
import Lucide from "src/base-components/Lucide";
import ReactSelect from "src/base-components/ReactSelect";
import SlideoverModal from "src/components/SlideoverModal";
import { RootState } from "src/stores";
import { connect } from "react-redux";
import { fetchAllUsers } from "src/stores/user";
import { assignThread, fetchCustomerDetail } from "src/services";

function Component({
  isOpen,
  onClose,
  users,
  fetchUsers,
  thread,
  authUser,
  closeParent,
}) {
  const [selectedId, setSelectedId] = useState<any>("");
  const [remarks, setRemarks] = useState<string>("");
  const [agents, setAgents] = useState<any>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [selectedQuery, setSelectedQuery] = useState<any>("");
  const [queries, setQueries] = useState<any>([]);
  const [searchBy, setSearchBy] = useState<any>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [queriesList, setQueriesList] = useState<any>([]);

  useEffect(() => {
    if (users) {
      const userOptions: any = [...users]?.map((item) => ({
        label: `${item.name || ""} ${item.last_name || ""}`,
        value: item.id,
      }));

      if (userOptions.length > 0) {
        setAgents(userOptions);
      }
    }
  }, [users]);

  const onSubmit = async () => {
    try {
      setSubmitting(true);

      const payload = {
        referenceId: thread?.referenceId,
        threadId: thread?.id,
        remarks: remarks,
        agentId: authUser?.id,
        assignedTo: selectedId,
        queryId: selectedQuery,
      };

      if (!payload) {
        throw new Error("Payload is invalid.");
      }

      await assignThread(payload);

      onClose();
      closeParent();
    } catch (error: any) {
      console.error("Error resolving thread:", error);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchUsers({ type: 1 });
  }, []);

  const searchOptions = [
    { label: "Phone", value: "phone" },
    { label: "Email", value: "email" },
  ];

  const searchCustomer = async () => {
    try {
      if (!searchValue && !searchBy) {
        alert("Please provide Value for search");
        return;
      }
      await fetchCustomerDetail({ search_by: searchBy, value: searchValue })
        .then((response: any) => {
          if (response) {
            setQueries(response);
            const payload = response?.map((item: any) => ({
              label: item?.query_no,
              value: item?.id,
            }));
            if (payload) {
              setQueriesList(payload);
            }
          }
        })
        .catch((error: any) => {
          console.log("Error : ", error);
        });
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleCloseAll = () => {
    setSelectedId("");
    setSearchBy("");
    setSearchValue("");
    setSelectedQuery("");
    setQueriesList([]);
    setRemarks("");
    setQueries([]);
  };

  return (
    <SlideoverModal
      size={"sm"}
      headerTitle={"Assign Chat"}
      visible={isOpen}
      className="p-3"
      onClose={(value) => {
        onClose();
        handleCloseAll();
      }}
    >
      <div className="h-full w-full flex flex-col">
        <div className="w-full mt-3">
          <FormLabel>Assign Chat</FormLabel>
          <ReactSelect
            options={agents}
            placeholder={"Assign"}
            required
            value={selectedId}
            onChange={(_, value) => {
              setSelectedId(value as any);
            }}
          />
        </div>

        <div className="w-full mt-3">
          <FormLabel>Search query by</FormLabel>
          <ReactSelect
            options={searchOptions}
            placeholder={"Select"}
            value={searchBy}
            required
            isSearchable={false}
            onChange={(_, value) => {
              setSearchBy(value as any);
            }}
          />
        </div>
        {searchBy && (
          <div className="w-full mt-3">
            <FormLabel className="w-full ">
              {searchBy === "phone" ? "Phone" : "Email"}
            </FormLabel>
            <FormInput
              value={searchValue}
              required
              inputMode={searchBy === "phone" ? "numeric" : "email"}
              type="text"
              onChange={(event: any) => {
                setSearchValue(event?.target?.value);
              }}
            />

            {queriesList?.length == 0 && (
              <Button
                type="button"
                className="w-full px-10 md:w-auto text-white bg-primary  ml-auto mt-3 "
                //   disabled={submitting || loading}
                //   loading={submitting}
                onClick={() => searchCustomer()}
              >
                Search
              </Button>
            )}
          </div>
        )}

        {queriesList?.length > 0 && (
          <div className="w-full mt-3">
            <FormLabel>Queries</FormLabel>
            <ReactSelect
              options={queriesList}
              placeholder={"Select Query"}
              value={thread?.queryId ?? selectedQuery}
              onChange={(_, value) => {
                setSelectedQuery(value as any);
              }}
            />
          </div>
        )}

        <div className="flex-1 w-full mt-3 ">
          <FormLabel>Remarks</FormLabel>
          <FormTextarea
            rows={5}
            value={remarks || ""}
            name="remark"
            required
            onChange={(event: any) => setRemarks(event?.target?.value)}
          />
          <FormHelp className="text-right">
            Maximum characters: {remarks ? remarks.length : 0}
            /2000
          </FormHelp>
        </div>

        <Button
          className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto"
          type="button"
          disabled={!selectedId || !remarks || submitting}
          onClick={onSubmit}
          loading={submitting}
        >
          <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-2 -ml-2" />
          Submit
        </Button>
      </div>
    </SlideoverModal>
  );
}

const mapStateToProps = ({ users, authUser }: RootState) => ({
  users: users?.data,
  authUser: authUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchUsers: (payload: any) => dispatch(fetchAllUsers(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
