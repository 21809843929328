import { call, put, takeLatest } from "redux-saga/effects";
import {
  CREATE_QUERY,
  CREATE_QUERY_ACTIVITY_LOG,
  FETCH_QUERY,
  FETCH_QUERY_ACTIVITY_LOG_LIST,
  FETCH_QUERY_LIST,
  QUERY_ASSIGN_TO,
  VIEW_PERMISSION_ACTION,
  UPDATE_QUERY,
  FORCE_CLOSE_QUERY,
  CALL_BACK_QUERY,
  FETCH_USER_QUERY_HISTORY,
  UPDATE_STATUS,
  FETCH_COLD_QUERY_LIST,
  RE_ASSIGN,
} from "./types";
import {
  fetchQueryActivityLogService,
  createQueryActivityLogService,
  createQueryService,
  fetchQueriesService,
  fetchQueryService,
  queryAssignToService,
  viewPermissionActionService,
  updateQueryService,
  forceCloseQuery,
  callBackQuery,
  fetchQueryHistoryService,
  fetchQueryQueuesService,
  updateQueryQueueService,
  updateQueryQue,
  updateQueryStatus,
  fetchColdQueriesService,
  queryReAssignService,
} from "../../services";
import {
  setActivityLogs,
  setColdQueries,
  setQueries,
  setQuery,
  setQueryQueues,
  setUserQueries,
} from "./slice";
import { setAlert } from "../basicSlices";

function* handleLoadQueries(
  { payload }: { payload?: any } = { payload: undefined }
): Generator<any> {
  try {
    const responseData: any = yield call(fetchQueriesService);
    yield put(setQueries(responseData));
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to fetch Queries!",
      })
    );
  } finally {
    payload?.callback && payload?.callback();
  }
}

export function* handleLoadQuery({
  payload,
}: {
  payload: {
    id: string;
    no?: string;
    canView?: boolean;
    callback?: Function;
  };
}): Generator<any> {
  try {
    const responseData: any = yield call(fetchQueryService, payload);
    if (responseData)
      yield put(
        setQuery({ ...responseData, canViewQuery: payload?.canView || false })
      );
    if (payload?.id) {
      yield handleLoadQueryActivityLogs({ payload: { id: payload?.id } });
    }
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to fetch Query!",
      })
    );
  } finally {
    payload?.callback && payload?.callback();
  }
}

export function* handleLoadUserQueryHistory({
  payload,
}: {
  payload: {
    id: string;
    no?: string;
    canView?: boolean;
    callback?: Function;
  };
}): Generator<any> {
  try {
    const responseData: any = yield call(fetchQueryHistoryService, payload);
    if (responseData) yield put(setUserQueries(responseData));
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to fetch Query!",
      })
    );
  } finally {
    payload?.callback && payload?.callback();
  }
}

function* handleCreateQuery({ payload }: { payload: any }): Generator<any> {
  try {
    const responseData: any = yield call(createQueryService, payload);
    // yield handleLoadQueries();
    payload?.callback &&
      payload?.callback(false, responseData?.id, responseData?.query_no);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to create new Query!",
      })
    );
    payload?.callback && payload?.callback(true);
  }
}

function* handleUpdateQuery({ payload }: { payload: any }): Generator<any> {
  try {
    const responseData: any = yield call(updateQueryService, payload);
    yield handleLoadQuery({ payload: { id: payload?.id } });
    payload?.callback && payload?.callback(false, responseData?.id);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to update query!",
      })
    );
    payload?.callback && payload?.callback(true);
  }
}

function* handleLoadQueryActivityLogs({
  payload,
}: {
  payload: { id: string; callback?: Function };
}): Generator<any> {
  try {
    const responseData: any = yield call(
      fetchQueryActivityLogService,
      payload?.id
    );
    yield put(setActivityLogs(responseData));
    payload?.callback && payload?.callback(false, responseData?.isAllLoaded);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to load Activity Logs!",
      })
    );
    payload?.callback && payload?.callback();
  }
}

function* handleCreateActivityLog({
  payload,
}: {
  payload: any;
}): Generator<any> {
  try {
    const responseData: any = yield call(
      createQueryActivityLogService,
      payload
    );
    yield handleLoadQuery({
      payload: { id: payload?.query_id, canView: payload?.canView },
    });
    payload?.callback && payload?.callback(false, responseData?.id);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to create Activity Log!",
      })
    );
    payload?.callback && payload?.callback(true);
  }
}

function* handleQueryAssignTo({ payload }: { payload: any }): Generator<any> {
  try {
    const responseData: any = yield call(queryAssignToService, payload);
    yield handleLoadQuery({
      payload: { id: payload?.id, canView: payload?.for == 1 },
    });
    payload?.callback && payload?.callback(false, responseData?.id);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to assign this Query!",
      })
    );
    payload?.callback && payload?.callback(true);
  }
}

function* handleQueryReAssign({ payload }: { payload: any }): Generator<any> {
  try {
    const responseData: any = yield call(queryReAssignService, payload);
    yield handleLoadQuery({
      payload: { id: payload?.id, canView: payload?.for == 1 },
    });
    payload?.callback && payload?.callback(false, responseData?.id);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to reassign this Query!",
      })
    );
    payload?.callback && payload?.callback(true);
  }
}

function* handleForceDelete({ payload }: { payload: any }): Generator<any> {
  try {
    const responseData: any = yield call(forceCloseQuery, payload);
    yield handleLoadQuery({
      payload: { id: payload?.id, canView: payload?.for == 1 },
    });
    payload?.callback && payload?.callback(false, responseData?.id);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to delete this Query!",
      })
    );
    payload?.callback && payload?.callback(true);
  }
}

function* handleCallBack({ payload }: { payload: any }): Generator<any> {
  try {
    const responseData: any = yield call(callBackQuery, payload);
    yield handleLoadQuery({
      payload: { id: payload?.id, canView: payload?.for == 1 },
    });
    payload?.callback && payload?.callback(false, responseData?.id);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to generate callback for this Query!",
      })
    );
    payload?.callback && payload?.callback(true);
  }
}

function* handleViewPermissionAction({
  payload,
}: {
  payload: { id: string; remark: string; for: string; callback?: Function };
}): Generator<any> {
  try {
    const responseData: any = yield call(viewPermissionActionService, payload);
    yield handleLoadQuery({ payload: { id: payload?.id } });
    payload?.callback && payload?.callback(false, responseData);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to view Permission Access!",
      })
    );
    payload?.callback && payload?.callback(true, null);
  }
}

function* handleUpdateStatus({ payload }: { payload: any }): Generator<any> {
  try {
    const responseData: any = yield call(updateQueryStatus, payload);
    yield handleLoadQuery({ payload: { id: payload?.query_id } });
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to update the Query Status!",
      })
    );
  } finally {
    payload?.callback && payload?.callback();
  }
}

function* handleLoadColdQueries(
  { payload }: { payload?: any } = { payload: undefined }
): Generator<any> {
  try {
    const responseData: any = yield call(fetchColdQueriesService);
    yield put(setColdQueries(responseData));
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to fetch Queries!",
      })
    );
  } finally {
    payload?.callback && payload?.callback();
  }
}

export default function* querySaga() {
  yield takeLatest<any>(FETCH_QUERY_LIST, handleLoadQueries);
  yield takeLatest<any>(FETCH_COLD_QUERY_LIST, handleLoadColdQueries);
  yield takeLatest<any>(FETCH_QUERY, handleLoadQuery);
  yield takeLatest<any>(FETCH_USER_QUERY_HISTORY, handleLoadUserQueryHistory);
  yield takeLatest<any>(CREATE_QUERY, handleCreateQuery);
  yield takeLatest<any>(UPDATE_QUERY, handleUpdateQuery);
  yield takeLatest<any>(
    FETCH_QUERY_ACTIVITY_LOG_LIST,
    handleLoadQueryActivityLogs
  );
  yield takeLatest<any>(CREATE_QUERY_ACTIVITY_LOG, handleCreateActivityLog);
  yield takeLatest<any>(QUERY_ASSIGN_TO, handleQueryAssignTo);
  yield takeLatest<any>(RE_ASSIGN, handleQueryReAssign);
  yield takeLatest<any>(FORCE_CLOSE_QUERY, handleForceDelete);
  yield takeLatest<any>(CALL_BACK_QUERY, handleCallBack);
  yield takeLatest<any>(VIEW_PERMISSION_ACTION, handleViewPermissionAction);
  yield takeLatest<any>(UPDATE_STATUS, handleUpdateStatus);
}
