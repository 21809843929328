import { queryUpdateProps } from "src/interfaces";
import { connect } from "react-redux";
import { RootState } from "src/stores";
import SlideoverModal from "src/components/SlideoverModal";
import { FormInput, FormLabel, FormSwitch } from "src/base-components/Form";
import clsx from "clsx";
import PhoneInput from "src/components/PhoneInput";
import Button from "src/base-components/Button";
import Lucide from "src/base-components/Lucide";
import { useEffect, useState } from "react";

const Component = ({
  visible,
  setVisible,
  model = {} as any,
  setModel,
  onSubmit,
  onChangeInput,
}: {
  visible: boolean;
  setVisible: Function;
  model: any;
  setModel: any;
  onSubmit: any;
  onChangeInput: any;
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  return (
    <SlideoverModal
      size="md"
      headerTitle={"Update Customer"}
      visible={visible}
      onClose={(value) => {
        setVisible(value);
      }}
    >
      <div className="h-full w-full flex flex-col">
        <div className="w-full mt-3">
          <FormLabel className="">Name</FormLabel>

          <FormInput
            name="fullname"
            value={model?.fullname}
            onChange={onChangeInput}
            placeholder="Enter customer name..."
            required
            type="text"
            className={clsx(
              "first:rounded-b-none first:md:rounded-bl-md ",
              "first:md:rounded-r-none [&:not(:first-child):not(:last-child)]:-mt-px ",
              "[&:not(:first-child):not(:last-child)]:md:mt-0 [&:not(:first-child):not(:last-child)]:md:-ml-px ",
              "[&:not(:first-child):not(:last-child)]:rounded-none last:rounded-t-none last:md:rounded-l-none ",
              "last:md:rounded-tr-md last:-mt-px last:md:mt-0 last:md:-ml-px focus:z-10"
            )}
          />
        </div>
        <div className="w-full mt-3">
          <FormLabel className="">Email</FormLabel>

          <FormInput
            name="email"
            value={model?.email}
            onChange={onChangeInput}
            placeholder="Enter customer email..."
            type="email"
            className={clsx(
              "first:rounded-b-none first:md:rounded-bl-md ",
              "first:md:rounded-r-none [&:not(:first-child):not(:last-child)]:-mt-px ",
              "[&:not(:first-child):not(:last-child)]:md:mt-0 [&:not(:first-child):not(:last-child)]:md:-ml-px ",
              "[&:not(:first-child):not(:last-child)]:rounded-none last:rounded-t-none last:md:rounded-l-none ",
              "last:md:rounded-tr-md last:-mt-px last:md:mt-0 last:md:-ml-px focus:z-10"
            )}
          />
        </div>
        <div className="w-full mt-3">
          <FormLabel className="">Phone</FormLabel>
          <div className="flex space-x-3">
            <div className="w-[50%]">
              <PhoneInput
                {...({} as any)}
                value={`${model?.country_code}${model?.phone}`}
                placeholder="#Phone"
                required={true}
                onChange={(data) => {
                  setModel((previous) => {
                    return {
                      ...previous,
                      country_code: data?.dialCode,
                      phone: data?.phone,
                    };
                  });
                }}
              />
            </div>
            <FormSwitch className="space-x-2">
              <FormSwitch.Input
                value={model?.isDefault}
                checked={model?.isDefault == 1}
                onChange={() => {
                  setModel((previous) => ({
                    ...previous,
                    isDefault: model?.isDefault == 0 ? 1 : 0,
                  }));
                }}
                type="checkbox"
              />
              <FormSwitch.Label className=" ">
                Same for whatsapp
              </FormSwitch.Label>
            </FormSwitch>
          </div>
        </div>
        {model?.isDefault == 0 && (
          <div className="w-full mt-3">
            <FormLabel className="">Whatsapp</FormLabel>
            <div className="flex space-x-3">
              <PhoneInput
                {...({} as any)}
                value={`${model?.wp_country_code}${model?.whatsapp_phone}`}
                placeholder="#Whatsapp Phone"
                required={true}
                onChange={(data) => {
                  setModel((previous) => {
                    return {
                      ...previous,
                      wp_country_code: data?.dialCode,
                      whatsapp_phone: data?.phone,
                    };
                  });
                }}
              />
            </div>
          </div>
        )}
        <Button
          className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto"
          type="button"
          onClick={onSubmit}
          disabled={submitting}
          loading={submitting}
        >
          <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-2 -ml-2" />
          Submit
        </Button>
      </div>
    </SlideoverModal>
  );
};

const mapStateToProps = ({}: RootState) => ({});

const mapDispatchToProps = (dispatch: any) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
