import { call, put, takeLatest } from "redux-saga/effects";
import {
  ACCEPT_QUOTATION,
  CREATE_QUOTATION,
  DELETE_TICKET,
  SEND_QUOTATION,
  SEND_TICKET,
  UPLOAD_TICKET,
} from "./types";
import {
  createQuotationService,
  acceptQuotationService,
  sendQuotationService,
  uploadTicketService,
  deleteTicketService,
  sendTicketService,
} from "../../services";
import { setAlert } from "../basicSlices";
import { handleLoadQuery } from "../query";

function* handleCreateQuotation({ payload }: { payload: any }): Generator<any> {
  try {
    const responseData: any = yield call(createQuotationService, payload);
    yield handleLoadQuery({ payload: { id: payload?.query_id } });
    payload?.callback && payload?.callback(false, responseData?.id);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to create Quotation!",
      })
    );
    payload?.callback && payload?.callback(true);
  }
}

function* handleAcceptQuotation({ payload }: { payload: any }): Generator<any> {
  try {
    const responseData: any = yield call(acceptQuotationService, payload);
    yield handleLoadQuery({ payload: { id: payload?.query_id } });
    payload?.callback && payload?.callback(false, responseData?.id);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to accept the Quotation!",
      })
    );
    payload?.callback && payload?.callback(true);
  }
}

function* handleSendQuotation({ payload }: { payload: any }): Generator<any> {
  try {
    const responseData: any = yield call(sendQuotationService, payload);
    yield handleLoadQuery({ payload: { id: payload?.query_id } });
    payload?.callback && payload?.callback(false, responseData?.id);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to send the Quotation!",
      })
    );
    payload?.callback && payload?.callback(true);
  }
}

function* handleUploadTicket({
  payload,
}: {
  payload: {
    query_id: string;
    quotation_id: string;
    ticket: string;
    email_content: string;
    callback?: Function;
  };
}): Generator<any> {
  try {
    const responseData: any = yield call(uploadTicketService, payload);
    yield handleLoadQuery({ payload: { id: payload?.query_id } });
    payload?.callback && payload?.callback(false, responseData);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to upload Ticket!",
      })
    );
    payload?.callback && payload?.callback(true, null);
  }
}

function* handleSendTicket({ payload }: { payload: any }): Generator<any> {
  try {
    const responseData: any = yield call(sendTicketService, payload);
    yield handleLoadQuery({ payload: { id: payload?.query_id } });
    payload?.callback && payload?.callback(false, responseData);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to upload Ticket!",
      })
    );
    payload?.callback && payload?.callback(true, null);
  }
}

function* handleDeleteTicket({
  payload,
}: {
  payload: {
    query_id: string;
    quotation_id: string;
    ticket: string;
    callback?: Function;
  };
}): Generator<any> {
  try {
    const responseData: any = yield call(deleteTicketService, payload);
    yield handleLoadQuery({ payload: { id: payload?.query_id } });
    payload?.callback && payload?.callback(false, responseData);
  } catch (error: any) {
    yield put(
      setAlert({
        text: error?.message,
        type: "danger",
        title: "Failed to delete Ticket!",
      })
    );
    payload?.callback && payload?.callback(true, null);
  }
}

export default function* quotationSaga() {
  yield takeLatest<any>(CREATE_QUOTATION, handleCreateQuotation);
  yield takeLatest<any>(ACCEPT_QUOTATION, handleAcceptQuotation);
  yield takeLatest<any>(SEND_QUOTATION, handleSendQuotation);
  yield takeLatest<any>(UPLOAD_TICKET, handleUploadTicket);
  yield takeLatest<any>(SEND_TICKET, handleSendTicket);
  yield takeLatest<any>(DELETE_TICKET, handleDeleteTicket);
}
