import {
  FETCH_QUERY_LIST,
  FETCH_QUERY,
  CREATE_QUERY,
  FETCH_QUERY_ACTIVITY_LOG_LIST,
  CREATE_QUERY_ACTIVITY_LOG,
  QUERY_ASSIGN_TO,
  VIEW_PERMISSION_ACTION,
  UPDATE_QUERY,
  FORCE_CLOSE_QUERY,
  CALL_BACK_QUERY,
  FETCH_USER_QUERY_HISTORY,
  UPDATE_STATUS,
  FETCH_COLD_QUERY_LIST,
  RE_ASSIGN,
} from "./types";

export const fetchQueries = (payload?: any) => {
  return {
    type: FETCH_QUERY_LIST,
    payload,
  };
};

export const fetchColdFollowUpQueries = (payload?: any) => {
  return {
    type: FETCH_COLD_QUERY_LIST,
    payload,
  };
};

export const fetchQuery = (payload: any) => {
  return {
    type: FETCH_QUERY,
    payload,
  };
};

export const fetchUserQueryHistory = (payload: any) => {
  return {
    type: FETCH_USER_QUERY_HISTORY,
    payload,
  };
};

export const createQuery = (payload: any) => {
  return {
    type: CREATE_QUERY,
    payload,
  };
};

export const updateQuery = (payload: any) => {
  return {
    type: UPDATE_QUERY,
    payload,
  };
};

export const fetchQueryActivityLogs = (payload?: any) => {
  return {
    type: FETCH_QUERY_ACTIVITY_LOG_LIST,
    payload,
  };
};

export const createQueryActivityLog = (payload: any) => {
  return {
    type: CREATE_QUERY_ACTIVITY_LOG,
    payload,
  };
};

export const queryAssignTo = (payload: any) => {
  return {
    type: QUERY_ASSIGN_TO,
    payload,
  };
};

export const reAssign = (payload: any) => {
  return {
    type: RE_ASSIGN,
    payload,
  };
};

export const forceCloseQuery = (payload: any) => {
  return {
    type: FORCE_CLOSE_QUERY,
    payload,
  };
};

export const callBackQuery = (payload: any) => {
  return {
    type: CALL_BACK_QUERY,
    payload,
  };
};

export const viewPermissionAction = (payload?: any) => {
  return {
    type: VIEW_PERMISSION_ACTION,
    payload,
  };
};

export const updateStatus = (payload: any) => {
  return {
    type: UPDATE_STATUS,
    payload,
  };
};
