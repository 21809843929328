import OneSignal from "react-onesignal";
import { fetchNotifications } from "src/stores/notification";
import { store } from "../stores";
import sound from "./notification-sound.mp3";
import { setAlert } from "src/stores/basicSlices";

export default async function runOneSignal() {
  try {
    const deviceToken = localStorage.getItem("deviceToken");
    if (Notification.permission == "granted") {
      const appId: any =
        process.env.REACT_APP_ONESIGNAL_APP_ID ||
        "cb0aa441-c5d7-4ff0-a056-e7dbf278176e";
      await OneSignal.init({
        appId: appId,
        allowLocalhostAsSecureOrigin: true,
      }).then(() => {
        const token: any = OneSignal.User.PushSubscription.id;
        console.log("sdfgsdfsdf", token);
        localStorage.setItem("deviceToken", token);
        return token;
      });
    } else if (Notification.permission == "denied") {
      localStorage.removeItem("deviceToken");
      return null;
    } else {
      return deviceToken;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

export const usePushNotification = () => {
  async function requestPermission() {
    try {
      const registrations = await navigator.serviceWorker.getRegistrations();
      let workerRegistered = false;

      for (const registration of registrations) {
        if (
          registration.active &&
          registration.active.scriptURL.includes("OneSignalSDKWorker.js")
        ) {
          break;
        }
      }

      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        if (!workerRegistered) {
          if ("serviceWorker" in navigator) {
            const registration = await navigator.serviceWorker.register(
              "/OneSignalSDKWorker.js"
            );
            console.log("Service Worker Registered");
          }
        } else {
          console.log("Service Worker is already registered.");
        }
      }
    } catch (error) {
      console.error(
        "Error during permission request or service worker registration:",
        error
      );
    }
  }

  requestPermission();

  const notificationHandler = () => {
    OneSignal.Notifications.addEventListener(
      "foregroundWillDisplay",
      (event: any) => {
        const isLoggedIn = store?.getState().authUser?.token;

        if (isLoggedIn) {
          new Audio(sound).play();

          store?.dispatch(fetchNotifications());
          store?.dispatch(
            setAlert({
              text: event.notification?.body || "",
              title: event?.notification?.title,
              alertType: "Snackbar",
              type: "success",
            })
          );
        } else {
          console.log("User is not logged in. Skipping notification.");
        }
      }
    );
  };

  notificationHandler();
};
