import React, { useEffect, useState } from "react";
import SlideoverModal from "../../../../components/SlideoverModal";
import {
  FormInput,
  FormLabel,
  FormSelect,
  FormTextarea,
  InputGroup,
} from "../../../../base-components/Form";
import {
  authUserProps,
  createUpdateQueryActivityLogProps,
  queryDataProps,
} from "src/interfaces";
import { connect } from "react-redux";
import { createQueryActivityLog } from "src/stores/query";
import { RootState } from "src/stores";
import Button from "src/base-components/Button";
import Lucide from "src/base-components/Lucide";
import { useNavigate } from "react-router-dom";

const Component = ({
  visible,
  setVisible,
  model = {} as any,
  setModel = () => {},
  onCreate,
  isViewOnly,
  authUser,
  fromDetail,
  setIsSubmitted = () => {},
  queryNumber,
  query,
}: {
  visible: boolean;
  setVisible: Function;
  model?: createUpdateQueryActivityLogProps;
  setModel?: (payload: createUpdateQueryActivityLogProps) => void;
  onCreate: (payload: createUpdateQueryActivityLogProps) => void;
  isViewOnly: boolean;
  authUser: authUserProps;
  query?: queryDataProps;
  fromDetail?: boolean;
  setIsSubmitted?: (payload: boolean) => void;
  queryNumber?: string;
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    let payload: any = model;
    Object.keys(payload)?.map((key) => {
      if (!payload[key]) {
        delete payload[key];
      }
    });
    onCreate({
      ...payload,
      canView: isViewOnly,
      query_id: query?.id,
      title:
        payload?.title ||
        (isViewOnly
          ? `Query #${queryNumber ?? ""} viewed by ${authUser?.name} ${
              authUser?.last_name || ""
            }`
          : ""),
      callback: (isError: boolean) => {
        if (fromDetail) {
          if (!!isError !== true) {
            setIsSubmitted(true);
          }
        }
        setVisible(!!isError);
        setLoading(false);
      },
    } as any);
  };
  return (
    <SlideoverModal
      size="md"
      headerTitle={isViewOnly ? "Reason of view query" : "Add Custom Note"}
      backdrop={!isViewOnly}
      visible={visible}
      onClose={(value) => {
        if (isViewOnly && !fromDetail) {
          navigate(-1);
        }
        setVisible(value);
      }}
    >
      <form onSubmit={onSubmit} className="h-full w-full flex flex-col">
        {!isViewOnly && (
          <>
            <FormLabel className="">Title</FormLabel>
            <FormInput
              value={model?.title}
              required
              type="text"
              onChange={(event) => {
                setModel({
                  ...model,
                  title: event?.target?.value,
                });
              }}
            />
          </>
        )}
        <FormLabel className="mt-3">Description</FormLabel>
        <FormTextarea
          rows={7}
          value={model?.description}
          required
          onChange={(event) => {
            setModel({
              ...model,
              description: event?.target?.value,
            });
          }}
        />
        <Button
          className="w-full px-10 md:w-auto text-white bg-primary mt-auto ml-auto"
          type="submit"
          disabled={loading}
          loading={loading}
        >
          <Lucide icon="Check" className="stroke-[1.3] w-4 h-4 mr-2 -ml-2" />
          Submit
        </Button>
      </form>
    </SlideoverModal>
  );
};

const mapStateToProps = ({ authUser }: RootState) => ({
  authUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  onCreate: (payload: any) => dispatch(createQueryActivityLog(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);
