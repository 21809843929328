import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { History } from "lucide";
// import { permissionProps, countProps } from "../../interfaces";

const initialState: any = {} as any;

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<any>) => {
      return { ...state, messages: action.payload };
    },
    removeMessages: (state) => {
      return { ...state, messages: [] };
    },
    setChatHistory: (state, action: PayloadAction<any>) => {
      return { ...state, history: action.payload };
    },
    removeChatHistory: (state) => {
      return { ...state, History: [] };
    },
    setChatCount: (state, action: PayloadAction<any>) => {
      return { ...state, chatCount: action.payload.chatCount };
    },
    removeChatCount: (state) => {
      return { ...state, chatCount: null };
    },
  },
});

export const {
  setMessages,
  removeMessages,
  setChatCount,
  removeChatCount,
  setChatHistory,
  removeChatHistory,
} = messagesSlice.actions;

export const selectPermissions = (state: RootState) => {
  return state.messages;
};

export default messagesSlice.reducer;
