import { convertToForm, convertToFormMultipleFiles } from "../../helpers";
import { store } from "../../stores";
import { apiBaseUrl as baseUrl } from ".";

const errorHandler = async (response: any) => {
  if (response.status == 403 || response.status == 401) {
    throw "You don't have permission, contact administrator";
  } else if (response?.status == 422) {
    const errors = await response?.json();
    let message = "";
    if (typeof errors == "object") {
      const keys = Object.keys(errors || {});
      keys?.map((key) => {
        message += errors[key] + ", ";
      });
    } else {
      message = "Please fill all required fields";
    }
    throw message;
  } else if (response?.status == 450) {
    const message = await response?.json();
    throw message;
  } else {
    throw "Something went wrong";
  }
};

export const Get = (
  url: string = "",
  token = store?.getState()?.authUser?.token,
  noBase = false
) => {
  const finalUrl = baseUrl + url;
  return fetch(noBase ? url : finalUrl, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  }).then(async (response) => {
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else {
      await errorHandler(response);
    }
  });
};

export const ExportGet = (
  url: string = "",
  token = store?.getState()?.authUser?.token,
  noBase = false
) => {
  const finalUrl = baseUrl + url;
  return fetch(noBase ? url : finalUrl, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  }).then(async (response) => {
    if (response.status === 200) {
      const data = await response.blob();
      return data;
    } else {
      await errorHandler(response);
    }
  });
};

const logFormData = (formData) => {
  for (let [key, value] of formData.entries()) {
    if (value instanceof File) {
      console.log(
        `${key}: File ${value.name} (type: ${value.type}, size: ${value.size} bytes)`
      );
    } else {
      console.log(`${key}: ${value}`);
    }
  }
};
export const Post = (
  url: string,
  payload = {},
  payloadType:
    | "application/json"
    | "Multiple"
    | "multipart/form-data" = "application/json",
  token = store?.getState()?.authUser.token,
  cBaseUrl = baseUrl
) => {
  console.log("URLS", url, cBaseUrl, payload);
  if (payloadType === "multipart/form-data") {
    return fetch(cBaseUrl + url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: convertToForm(payload),
    }).then(async (response) => {
      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        await errorHandler(response);
      }
    });
  } else if (payloadType === "Multiple") {
    return fetch(cBaseUrl + url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: convertToFormMultipleFiles(payload),
    }).then(async (response) => {
      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        await errorHandler(response);
      }
    });
  }
  return fetch(cBaseUrl + url, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(payload),
  }).then(async (response) => {
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else {
      await errorHandler(response);
    }
  });
};

export const Postx_www_form = (
  url: string,
  payload = {},
  cBaseUrl = baseUrl,
  headers?: Object
) => {
  let requestHeader = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  if (headers) {
    requestHeader = { ...requestHeader, ...headers };
  }
  return fetch(cBaseUrl + url, {
    method: "POST",
    headers: requestHeader as any,
    body: payload as any,
  }).then(async (response) => {
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else {
      await errorHandler(response);
    }
  });
};

export const Patch = (
  url: string,
  payload = {},
  token = store?.getState()?.authUser?.token
) => {
  return fetch(baseUrl + url, {
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(payload),
  }).then(async (response) => {
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else {
      await errorHandler(response);
    }
  });
};

export const Delete = (
  url: string,
  payload = {},
  token = store?.getState()?.authUser?.token
) => {
  return fetch(baseUrl + url, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(payload),
  }).then(async (response) => {
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else {
      await errorHandler(response);
    }
  });
};

const http = { Get, ExportGet, Post, Postx_www_form, Patch, Delete };
export default http;
